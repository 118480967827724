@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&family=Poppins:wght@700&display=swap");

//design for the new quote component
input[type="number"] {
  -moz-appearance: textfield;
}

// @font-face {
//   font-family: "MainFont";
//   src: url("../fonts/SinkinSansLight.ttf");
// }
// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
@font-face {
  font-family: "OpenSansItalic";
  src: local("OpenSansItalic"),
    url("../fonts/OpenSans-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "OpenSansRegular";
  src: local("OpenSansRegular"),
    url("../fonts/OpenSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "OpenSansSemiBold";
  src: local("OpenSansSemiBold"),
    url("../fonts/OpenSans-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoSlabRegular";
  src: local("RobotoSlabRegular"),
    url("../fonts/RobotoSlab-Regular.ttf") format("truetype");
}

.font-family-open-sans-semi-bold {
  font-family: "MontserratRegular" !important;
}

.font-family-open-sans-italic {
  font-family: "OpenSansItalic" !important;
}

// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
@font-face {
  font-family: "MontserratRegular";
  src: local("MontserratRegular"),
    url("../fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "MontserratMedium";
  src: local("MontserratMedium"),
    url("../fonts/Montserrat-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "MontserratSemiBold";
  src: local("MontserratSemiBold"),
    url("../fonts/Montserrat-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "MontserratBold";
  src: local("MontserratBold"),
    url("../fonts/Montserrat-Bold.ttf") format("truetype");
}

.font-family-montserrat-bold {
  font-family: "MontserratBold";
}

.font-family-montserrat-semi-bold {
  font-family: "MontserratSemiBold";
}

.font-family-montserrat-medium {
  font-family: "MontserratMedium";
}

.font-family-montserrat-regular {
  font-family: "MontserratRegular";
}

input {
  padding: 0.5rem;
}

.per5 {
  width: 5%;
  max-width: 5%;
}

.per7 {
  width: 6%;
  max-width: 6%;
}

.per20 {
  width: 20%;
  max-width: 20%;
}

.per10 {
  max-width: 10%;
  width: 10%;
}

body {
  font-family: "MontserratRegular";
  -webkit-font-smoothing: antialiased;
  color: black;
  // font-size: 13.7px;
  font-size: 16px;
}

.home1 {
  word-break: break-all;
  // font-family: "Rokkitt", serif;
  font-weight: 400;
  line-height: 1.1;

  .header {
    width: 100%;

    .logos-container {
      padding: 0.4rem 0;
      margin-left: 2.5rem;

      .solve-logo-container {
        height: 4.65rem;

        img {
          max-height: 100%;
        }
      }

      .insurecomp-logo-container {
        height: 3.28rem;
        margin-top: -1.4rem;

        img {
          max-height: 100%;
        }
      }
    }
  }

  .image-block {
    cursor: pointer;
    position: relative;
    height: 22rem;
    overflow: hidden;

    img {
      max-width: 100%;
    }

    .image-text-container {
      padding: 1rem 0;
      background: rgba(24, 54, 80, 0.6);
      position: absolute;

      .image-text {
        font-size: 3.125rem;
        font-weight: bolder;
        color: white;
      }
    }
  }

  .stats-1 {
    width: 100%;
    margin-bottom: 4rem;

    .stat-1-1,
    .stat-1-2 {
      flex: 1;

      span {
        font-weight: bolder;
        font-size: 2.5rem;
      }

      img {
        height: 5.5rem;
        max-height: 100%;
        max-width: 100%;
      }
    }

    .stat-1-1 {
      img {
        height: 6rem;
      }

      span {
        margin-left: 1.4rem;
      }
    }
  }

  .confirm-quote {
    width: 100%;

    button {
      border-radius: 10px;
      box-shadow: 2px 4px 10px #23b4bd;
      font-size: 3.125rem;
      font-weight: 700;
      color: white;
      background-color: #23b4e8;
      width: 35rem;
      padding: 0.5rem 0;
    }
  }

  .services {
    padding: 3rem 0 4rem 0;

    .services-header {
      font-size: 4rem;
      font-weight: bolder;
      margin-bottom: 3rem;
    }

    .services-list-block {
      .services-list-1 {
        margin-bottom: 3rem;

        .services-1-1,
        .services-1-2,
        .services-1-3 {
          flex: 1;

          span {
            font-weight: bolder;
            font-size: 2.5rem;
          }

          img {
            margin-bottom: 1.5rem;
            height: 5.5rem;
            max-height: 100%;
            max-width: 100%;
          }
        }
      }

      .services-list-2 {

        .services-2-1,
        .services-2-2,
        .services-2-3 {
          flex: 1;

          span {
            font-weight: bolder;
            font-size: 2.5rem;
          }

          img {
            margin-bottom: 1.5rem;
            height: 5.5rem;
            max-height: 100%;
            max-width: 100%;
          }
        }
      }
    }
  }

  .stats-banner {
    background-color: #183650;
    padding: 2.5rem 0;

    .stats-banner-item-1,
    .stats-banner-item-2,
    .stats-banner-item-3 {
      flex: 1;
      font-size: 2.5rem;
      font-weight: bolder;
      color: white;
    }
  }

  .testimonials {
    font-size: 2rem;
    margin: 2rem;

    .testimonials-text {
      font-style: italic;
    }
  }
}

.logout-div {
  margin-right: 2.5rem;
  font-size: 16px;
}

.logout-div:hover {
  text-decoration: underline;
  cursor: pointer;
}

.home2 {
  // font-family: "Poppins", serif;
  font-weight: 400;
  line-height: 1.1;

  .footer {
    width: 100%;
    height: 3rem;
    background-color: #183650;
    font-size: 2rem;

    a,
    span {
      margin-right: 2.5rem;
      color: white;
    }

    span:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .header {
    width: 100%;

    .logos-container {
      padding: 0.4rem 0;
      margin-left: 2.5rem;

      .solve-logo-container {
        height: 6.65rem;

        img {
          max-height: 100%;
        }
      }

      .insurecomp-logo-container {
        height: 3.28rem;
        margin-top: -1.4rem;

        img {
          max-height: 100%;
        }
      }
    }
  }

  .image-block {
    cursor: pointer;
    position: relative;
    height: 22rem;
    overflow: hidden;

    img {
      max-width: 100%;
    }

    .image-text-container {
      padding: 1rem 0;
      background: rgba(24, 54, 80, 0.6);
      position: absolute;

      .image-text {
        font-size: 3.125rem;
        font-weight: bolder;
        color: white;
      }
    }
  }

  .stat-2-1 {
    margin-right: 2.5rem;
    word-break: break-word;

    span {
      width: inherit;
      font-weight: bolder;
      font-size: 2.5rem;
    }

    img {
      height: 5.5rem;
      max-height: 100%;
      max-width: 100%;
    }
  }

  .confirm-quote {
    width: 100%;

    button {
      border-radius: 10px;
      box-shadow: 2px 4px 10px #eea236;
      font-size: 3.125rem;
      font-weight: 700;
      color: white;
      background-color: #ffd13f;
      border-color: #eea236;
      width: 35rem;
      padding: 0.5rem 0;
    }
  }

  .stats-banner {
    background-color: #183650;
    padding: 2.5rem 0;

    .stats-banner-item-1,
    .stats-banner-item-2,
    .stats-banner-item-3 {
      flex: 1;
      font-size: 2.5rem;
      font-weight: bolder;
      color: white;
    }
  }

  .testimonials {
    font-size: 2rem;
    margin: 2rem;

    .testimonials-text {
      font-style: italic;
    }
  }
}

class-desc-select .class-desc-select__menu {
  height: fit-content;
  width: fit-content;
  max-width: 500px;
  min-width: -webkit-fill-available;
  min-width: -moz-available;
}

.contentHolder-history {
  min-height: calc(100vh - 15.75rem);
}

@mixin cmprQts {
  //style of the background image:
  background-image: url("../images/backimage4.png");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 95%;
  background-attachment: sticky;

  .quote-table {
    .quote-table-company-name {
      padding-right: calc(2rem + 25px);
    }

    .head-row {
      align-items: center;
      justify-content: space-between;
    }

    .hr1 {
      color: #2c2e33;
      font-size: 26px;
      font-weight: 500;

      &.quote-table-company-name {
        // font-family: "Overpass", sans-serif;
        font-size: 20px;
        font-weight: 600;
      }
    }

    .hr2 {
      color: #2c2e33;
      width: 70%;
      margin-top: 1rem;
      margin-bottom: 1rem;

      span {
        color: #5f636b;
      }
    }

    .hr3 {
      margin-bottom: 1.5rem;
      color: #2c2e33;
    }

    .body-row {
      padding-left: 6px;
      margin-top: 2rem;

      // justify-content: left;
      .table {
        margin-bottom: 7rem;
        position: relative;
        right: 2rem;
        border-collapse: separate;
        border-spacing: 25px 0px;

        thead {
          th {
            color: #2c2e33;
            position: relative;
            right: 0.6rem;
            font-size: 16px;
          }
        }

        tbody {
          padding-left: 5px;
          padding-right: 5px;
          font-size: 18px;

          td,
          th {
            width: 25%;
            border: 1px solid white;
            background-color: white;
            color: #2c2e33;
            font-size: 18px;
            font-weight: normal;

            div {
              height: 100%;

              label {
                margin-bottom: 0;
              }
            }
          }

          td {
            label {
              font-size: 22px;
              font-weight: 800;
              color: #1463ac;
              margin-bottom: 0;
            }

            .best {
              font-size: 14px;
              margin-left: 1rem;
              color: #038b00;
            }

            .higher {
              font-size: 14px;
              margin-left: 1rem;
              color: #2c2e33;
            }

            .uw {
              font-size: 14px;
              margin-left: 1rem;
            }

            input {
              margin: 0.5rem;
            }
          }
        }
      }
    }
  }
}

.new-navbar {
  margin-bottom: 1rem;
  height: 2rem;
  background-color: #003764;
  color: white;
  font-weight: 600;
  font-size: 16px;

  .pride-logo {
    margin-left: 2.2rem;
  }

  .insurecomp-logo {
    margin-right: 2.2rem;
  }

  .forBig {
    margin-left: auto;
  }

  .new-logo {
    // margin-top: 0.5rem;
    height: 3.375rem;
    // margin-left: 2.2rem;
  }

  .boxed-logo {
    background-color: #fff;
    padding: 20px;
    height: 125px;
    margin: 5px 20px;
    border-radius: 5px;
    -webkit-box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
    margin-top: 20px;
    margin-left: 40px;
  }

  button {
    border: none;
    color: white;
    background: none;
    font-weight: 600;
  }

  span {
    color: white;
    font-weight: 600;
  }

  ul {
    li {
      // padding-bottom: 10px;
      margin-right: 1rem;
      margin-left: 1rem;
    }
  }
}

.secDesc {
  text-align: center;
  font-size: 16px;
  margin-bottom: 30px;
}

#small-loader {
  width: fit-content;
  height: fit-content;

  /* margin-top: 1rem; */
  >img {
    width: 2rem;
  }
}

#rate1,
#rate2 {
  margin-bottom: 5px;
}

#compareQuote {
  .btnSubmits {
    margin-top: 2rem;
    width: 210px;
    height: 40px;
    border-radius: 5px;
    background-color: #ffd13f;
    border-color: #eea236;
    box-shadow: none;
    color: white;
    // font-size: 14px;
    font-weight: 800;
  }

  .btnView {
    width: 210px;
    height: 40px;
    border-radius: 5px;
    background-color: #1463ac;
    margin-right: 1rem;
    margin-top: 2rem;
    // font-size: 14px;
    font-weight: 800;
  }

  // .iFrameContainer {
  //   width: 100%;
  //   height: 700px;
  //   margin-top: 1rem;
  //   justify-content: center;
  //   margin-bottom: 3.5rem;
  //   iframe {
  //     width: 100%;
  //     height: 100%;
  //   }
  // }
}

.errMsg {
  color: red;
}

.admin-dashboard {
  .discount-body {

    input,
    div {
      font-size: 12px;
    }
  }
}

.tab1,
.tab2,
.tab3,
.tab4 {
  thead {
    tr:first-child th {
      width: 10px;
    }
  }

  tbody {
    tr:first-child td {
      width: 10px;
    }
  }
}

.tab1,
.tab2,
.tab3,
.tab4 {
  tbody tr:first-child td:first-child {
    // background-image: url("../images/filterimage.png");
    background-image: url("../images/filter_excel.png");
    background-repeat: no-repeat;
    background-size: 70% 80%;
    top: 5px !important;
    left: 4px !important;
    position: relative !important;
  }
}

.sub_tab1 tbody tr:first-child td:first-child {
  background-image: none;
  top: 0 !important;
  left: 4px !important;
  position: none !important;
}

.sub_tab2 tbody tr:first-child td:first-child {
  background-image: none;
  top: 0 !important;
  left: 4px !important;
  position: none !important;
}

.sub_tab3 tbody tr:first-child td:first-child {
  background-image: none;
  top: 0 !important;
  left: 4px !important;
  position: none !important;
}

.sub_tab4 tbody tr:first-child td:first-child {
  background-image: none;
  top: 0 !important;
  left: 4px !important;
  position: none !important;
}

.preUWDashboard {
  thead {
    tr:first-child th {
      width: 10px;
    }
  }

  tbody {
    tr:first-child td {
      width: 10px;
    }
  }
}

.header {
  background-color: white;
  width: 100%;

  .logos-container {
    padding: 0.4rem 0;
    margin-left: 2.5rem;

    .solve-logo-container {
      height: 5rem;

      img {
        max-height: 100%;
      }
    }

    .insurecomp-logo-container {
      height: 2.47rem;
      margin-top: -1rem;

      img {
        max-height: 100%;
      }
    }
  }
}

.stat-2-1 {
  margin-right: 2.5rem;
  word-break: break-word;

  span {
    width: max-content;
    font-weight: bolder;
    font-size: 1rem;
  }

  img {
    height: 5.5rem;
    max-height: 100%;
    max-width: 100%;
  }
}

.upload-docs-action {
  border-radius: 7.2px;
  background-color: #ffd13f;
  border-color: #eea236;
  box-shadow: 2px 4px 12px #eea236;
  color: white;
  font-size: 1rem;
  font-weight: 800;
}

.newquote-mainbody {
  // font-family: "Rokkitt", serif;
  //global styles for quote main body
  background-color: #e6edf0;
  width: 100%;
  padding-left: 0;
  padding-right: 0;

  .forSmall {
    display: none;
  }

  .upload-docs-action {
    border-radius: 7.2px;
    background-color: #ffd13f;
    border-color: #eea236;
    box-shadow: 2px 4px 12px #eea236;
    color: white;
    font-size: 1rem;
    font-weight: 800;
  }

  .newquote-content {
    //global styling for everything in the light blue background
    padding-left: 1.5rem;
    padding-right: 1.6rem;
    width: 90%;
    margin-top: 1rem;
    padding-bottom: 2rem;
    background-color: #e2f2ff;

    .emodDate,
    form select,
    form input[type="text"],
    form input[type="email"],
    form input[type="password"],
    form input[type="date"],
    form textarea {
      width: 100%;
      // font-size: 0.85em;
      border-radius: 4px;
      border: 1px solid white;
      background: white;
      color: black; //#2b2d31;
      font-weight: "normal";
    }

    input[type="checkbox"] {
      height: 16px !important;
      width: 16px;
      border-radius: 4px;
      border: solid 1px #979797;
      background-color: #ffffff;
    }

    //styling of the headers and section-headers:
    // this is styling for all the different stages
    // for changing any heading, please refer to this section only
    .formTitle {
      color: #2c2e33;
      // font-family: "Rubik", sans-serif;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 1.38;

      &.location-details-heading {
        font-weight: 400;
        font-size: 1.2rem;
      }

      &.compName {
        // font-family: Overpass;
        font-size: 20px;
        font-weight: 600;
        color: #2c2e33;
        // padding-left: 4rem;
      }

      &.title2 {
        width: 70%;
        display: inline-block;
      }

      &.title3 {
        font-size: 20px;
        font-weight: normal;
      }
    }

    .addressRate,
    .aggregate-heading,
    .stateAggName {
      // font-family: Overpass;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #2b2d31;
    }

    //end of heading/subheading styles
    //styling of buttons:
    .transpButton {
      background-color: transparent;
      border-color: transparent;
    }

    .btnView {
      width: 210px;
      height: 40px;
      border-radius: 5px;
      background-color: #1463ac;
      margin-right: 1rem;
      margin-top: 2rem;
      // font-size: 14px;
      font-weight: 800;
    }

    .proceed-bind,
    .upload-docs {
      border-radius: 7.2px;
      background-color: #ffd13f;
      border-color: #eea236;
      // background-image: linear-gradient(to right, #10c03e, #0eaf84);
      // box-shadow: none;
      box-shadow: 2px 4px 12px #eea236;
      // border-color: transparent;
      color: white;
      font-size: 1rem;
      font-weight: 800;
    }

    .btnSubmits {
      // margin-top: 2rem;
      border-radius: 7.2px;
      background-color: #ffd13f;
      border-color: #eea236;
      box-shadow: 2px 4px 12px #eea236;
      color: white;
      font-size: 1rem;
      font-weight: 800;
    }

    // end of button styling
    //style for the progress bar at the top of every page
    // please refer to this section only for styling the progress bar
    //#3e9ccc
    .color-bar {
      margin-top: 1.5rem;
      margin-bottom: 25px;
      // margin-left: 1.2rem;
      width: 100%;
      height: 12px;
      border-radius: 8px;
      background-color: #8ab93f;
    }

    // end of progress bar styles
    //style for company profile tab
    #paymentQuote {
      span.errMsg {
        color: red;
        font-size: 11px;
      }
    }

    #compProfile {

      // background-image: url("../images/backimage1.png");
      @media screen and (max-width: 992px) {
        .row {
          display: flex;
          flex-direction: column;
        }

        #phone-number,
        #entity-type,
        #years-in-business,
        #expected-start-date,
        #street-1,
        #zip-1,
        #city-1,
        #state-1,
        #street-2,
        #zip-2,
        #city-2,
        #state-2 {
          width: 100% !important;
        }
      }

      background-repeat: no-repeat;
      background-position-x: 95%;
      background-position-y: 95%;
      background-attachment: sticky;

      .class-desc-select {
        input {
          box-shadow: none !important;
        }
      }

      .pink-sign {
        font-weight: 800;
        margin-left: 15px;
        color: rgb(255, 255, 255);
        background-color: #c0224d;
        border-radius: 50%;
        width: 29px;
        height: 29px;
        display: flex;
        justify-content: center;
        cursor: pointer;
      }

      .company-profile-container,
      .question-list-container,
      .location-payroll-container {
        margin-bottom: 5px;
      }

      .location-payroll-container {
        #perCKdollar {
          width: 6rem;
        }

        #adminPercent {
          width: 5rem;
        }

        #requested-pricing-radio-yes,
        #requested-pricing-radio-no {
          margin-inline: 10px;
          // align-self: baseline;
        }

        input[type="radio"] {
          width: 18px;
          height: 18px;
          display: grid;
          place-content: center;
          background-color: transparent;
          cursor: pointer;
        }

        input[type="radio"]::after {
          content: "";
          width: 0.65em;
          height: 0.65em;
          border-radius: 40%;
          transform: scale(0);
          transition: 2s transform ease-in-out;
          box-shadow: inset 1em 1em var(--form-control-color);
        }

        input[type="radio"]:checked::after {
          transform: scale(1);
          border: 1px solid #0763ee83;
          box-shadow: #0763ee83;
          transition: 2s transform ease-in-out;
        }
      }

      .question-list-container {
        td {
          padding-left: 0px;
        }
      }

      #sameAsPrimary-1 {
        box-shadow: none;
        border: 2px solid black !important;
        width: 14px !important;
      }

      #locSameAsPrimary-1 {
        box-shadow: none;
        border: 2px solid black !important;
        width: 13.5px !important;

        margin-bottom: 0.5rem;
      }

      .locSameAsPrimary-1-label {
        font-size: 16px;
      }

      .question-list-container {
        width: 80%;

        input[type="radio"] {
          width: 20px;
          height: 20px;
          display: grid;
          place-content: center;
          background-color: transparent;
          cursor: pointer;
        }

        input[type="radio"]:after {
          content: "";
          width: 0.65em;
          height: 0.65em;
          border-radius: 40%;
          transform: scale(0);
          transition: 2s transform ease-in-out;
          box-shadow: inset 1em 1em var(--form-control-color);
        }

        input[type="radio"]:checked:after {
          transform: scale(1);
          border: 1px solid #0763ee83;
          box-shadow: #0763ee83;
          transition: 2s transform ease-in-out;
        }

        // input[type="radio"]:after {
        //   width: 20px;
        //   height: 20px;
        //   border-radius: 20px;
        //   top: -3px;
        //   left: -1px;
        //   position: relative;
        //   background-color: #ffffff;
        //   content: "";
        //   display: inline-block;
        //   visibility: visible;
        //   border: 1px solid black;
        // }
        // input[type="radio"]:checked:after {
        //   width: 20px;
        //   height: 20px;
        //   border-radius: 20px;
        //   top: -3px;
        //   left: -1px;
        //   position: relative;
        //   background-color: #6e98e0;
        //   content: "";
        //   display: inline-block;
        //   visibility: visible;
        //   border: 1px solid black;
        // }
        // input[type="radio"]:after {
        //   transition: transform(0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75));
        //   transform: scale(0, 0);
        //   content: "";
        //   position: absolute;
        //   top: 0;
        //   left: rem(2);
        //   z-index: 1;
        //   width: 20px;
        //   height: 20px;
        //   background: #a04916;
        //   border-radius: 50%;
        // }
        // input[type="radio"]:checked:before {
        //   transform: scale(1, 1);
        // }
        // input[type="radio"]:after {
        //   content: "";
        //   position: absolute;
        //   top: rem(-4);
        //   left: rem(-2);
        //   width: 1rem;
        //   height: 1rem;
        //   background: #fff;
        //   border: 2px solid #f2f2f2;
        //   border-radius: 50%;
        // }
        .form-check-label {
          margin-left: 5px;
          font-size: 15px;
        }
      }

      .addLoc {
        margin-bottom: 18px;
      }

      .react-datepicker-wrapper {
        width: -webkit-fill-available;
        width: -moz-available;
        width: stretch;

        .react-datepicker__input-container {
          width: 100%;
        }
      }

      #healthQues,
      #otp {
        font-size: inherit;
      }

      .formTitle {
        margin-bottom: 34px;
        font-size: 24px;
        font-weight: bold;
      }

      .company-locations,
      .emod-ques-heading {
        margin-bottom: 30px;
      }

      .mandate {
        color: #919399;
      }

      small {
        color: #333333;
      }

      .form-group {
        margin-bottom: 38px;

        select,
        input {
          height: 35px;
          color: black;
          // font-size: 14px;
          padding-left: 5px;
          // box-shadow: 0 0 4px rgba(0, 0, 0, 0.6);
          box-shadow: 0.5px 1px 4px grey;
        }

        textarea {
          color: black;
          // font-size: 14px;
          padding-inline: 15px;
          // box-shadow: 0 0 4px rgba(0, 0, 0, 0.6);
          box-shadow: 0.5px 1px 4px grey;
        }

        label {
          -webkit-line-clamp: 1;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          // font-size: 11px;
          color: #333;
          margin-bottom: 0;

          &.mandatory::after {
            content: "*";
            padding-left: 2px;
          }
        }

        p.errMsg {
          color: red;
          font-size: 11px;
        }
      }

      textarea {
        // padding-left: 5px;
        height: 123px;
      }

      #allLocations {
        margin-top: 6px;
      }

      .emod-ques {
        margin-top: 44px;
      }

      .address-delete {
        margin-top: 1.2rem;
      }

      .linkLike {
        position: relative;
        bottom: 20px;
      }

      #otpSendMsg,
      #otpSentMsg,
      #otpVerify,
      #verifySuc {
        display: none;
        font-size: 0.8em;
      }

      #otpSec,
      #resendOTP {
        display: none;
      }

      #emailStatus,
      #verifyStatus {
        margin-top: 1rem;
      }

      #timerOTP {
        font-size: 0.8rem;
      }

      .btn-link {
        padding-left: 0;
      }
    }

    #cmprRate {
      //style block for compare rate page/tab
      //styling for the background image
      background-image: url("../images/backimage2.png");
      background-repeat: no-repeat;
      background-position-x: 95%;
      background-position-y: 100%;
      background-attachment: sticky;

      // .download-link-div {
      //   width: 20px;
      // }
      .formTitle {
        margin-bottom: 60px;
      }

      .title3 {
        margin-bottom: 25px;
      }

      .linkLike {
        margin-bottom: 37px;
      }

      .calcPremium {
        color: #919399;

        input {
          height: 36px;
          color: #2b2d31;
          font-size: 14px;
          padding-right: 5px;
        }
      }

      .rangeSlider {
        padding: 1.2rem;

        .row {
          // justify-content: space-around;
          margin: 0;
          width: 100%;
        }

        .premVal {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 35%;
          height: 35px;
          border-radius: 20px;
          background-color: #2987de;
          font-size: 20px;
          font-weight: 800;
          color: #ffffff;

          span {
            position: relative;
            top: 2px;
          }
        }

        .barBG {
          margin-left: 1rem;
          margin-right: 1rem;
          margin-top: 1rem;
          width: 12px;
          height: 2px;
          background-color: #2c2e33;
        }
      }

      #attachSec {
        margin-top: 47px;

        .no-lossrun-affidavit-heading {
          color: #2b2d31;
          font-weight: "normal" !important;
          font-size: 14px;
        }

        .dropped-file {
          color: #4169ba;
        }

        .drop-zone {
          margin-top: 20px;
          margin-bottom: 44px;
          display: flex;
          background: white;
          opacity: 0.5;
          justify-content: center;
          align-items: center;
          height: 106px;
          width: 385px;
          border: 1px dashed black;

          span {
            color: #1463ac;
          }
        }

        .drop-info {
          margin-top: 20px;
        }
      }

      .grey-table-row {
        position: relative;
        right: 7px;
      }

      #claimTable {
        input {
          height: 36px;
          margin-bottom: "none";
          color: #2b2d31;
          font-size: 14px;
        }

        color: #919399;
        font-weight: "normal";
        border-collapse: separate;
        border-spacing: 20px 0px;

        .row-no-coverage {
          .no-coverage {
            width: 30%;
            margin-bottom: 20px;

            input {
              height: 12px;
              margin-bottom: 25px;
              margin-right: 5px;
            }
          }
        }
      }

      .btnSubmits {
        margin-top: 30px;
        margin-bottom: 36px;
      }

      .form-group {

        select,
        input {
          height: 35px;
          color: #2b2d31;
          // font-size: 14px;
          padding-left: 5px;
        }

        label {
          -webkit-line-clamp: 1;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          // font-size: 11px;
          color: #333;
          margin-bottom: 0;

          &.mandatory::after {
            content: "*";
            padding-left: 2px;
          }
        }

        p.errMsg {
          color: red;
          font-size: 11px;
        }
      }

      #claimTable {
        .form-group {
          margin-bottom: 2px;
        }
      }
    }

    #undrQues {
      // style for the background image:
      // background-image: url("../images/backimage3.png");
      background-repeat: no-repeat;
      background-position-x: 95%;
      background-position-y: 95%;
      background-attachment: sticky;

      label {
        font-weight: 200;
      }

      input[type="radio"] {
        width: 20px;
        height: 20px;
        display: grid;
        place-content: center;
        background-color: transparent;
        cursor: pointer;
      }

      input[type="radio"]:after {
        content: "";
        width: 0.65em;
        height: 0.65em;
        border-radius: 40%;
        transform: scale(0);
        transition: 2s transform ease-in-out;
        box-shadow: inset 1em 1em var(--form-control-color);
      }

      input[type="radio"]:checked:after {
        transform: scale(1);
        border: 1px solid #0763ee83;
        box-shadow: #0763ee83;
        transition: 2s transform ease-in-out;
      }

      //style for the unerwriter questions and additional questions table:
      .heading-row {
        justify-content: space-between;

        .compName {
          margin-top: 2px;
          color: #2c2e33;
          font-size: 20px;
          font-weight: 600;
        }
      }

      .title2 {
        margin-bottom: 50px;
      }

      .table-undrques-container {
        margin-bottom: 3.5rem;
      }

      .table-undrques {
        margin-bottom: 0px !important;
      }

      .table-addnques,
      .table-undrques {
        thead {
          color: #2c2e33;
          font-size: 1.2rem;

          tr {
            th {
              padding: 1.7rem 0;
              border: none;

              .pink-header {
                margin-bottom: 0;
              }
            }
          }
        }

        tbody {
          tr {
            margin-bottom: 1rem;

            .tableQuesCol {
              width: 90%;
            }

            .tableAns {
              width: 5%;
              padding-left: 2rem;
            }

            td {
              padding: 0.8rem 0;
              vertical-align: middle;

              &.tableAns {
                .form-check-inline {
                  margin-right: 0;
                }

                .radio-btn-no {
                  margin-left: 2.5rem;
                  width: 18px;
                  height: 18px;
                }

                .radio-btn-yes {
                  margin-left: 1rem;
                  width: 18px;
                  height: 18px;
                }

                .radiobtn {
                  margin-left: 4px;
                  position: relative;
                  bottom: 4px;
                }
              }

              &.tableQues {
                width: 80%;
              }

              &.tableRemarks {
                padding-right: 5px;

                input {
                  height: 40px;
                  border: 1px solid rgba(36, 181, 233, 1);
                  border-radius: unset;
                }

                .hidden {
                  background-color: rgba(131, 165, 191, 0.2);
                }
              }

              &.table-question {
                margin: 10px;
              }
            }
          }
        }
      }
    }

    .additional-questions {
      margin-bottom: 30px;
    }

    .table-addnques {
      .tableAns {
        width: 18%;
      }
    }

    .btn-undrques {
      margin-bottom: 2rem;
    }

    #cmprQts {
      @include cmprQts;

      #compareQuote {
        margin-top: 3rem;
      }

      .quote-table {
        button {
          color: white;
          font-family: "MontserratSemiBold";
          border-radius: 12px;
        }

        .peo-btn {
          font-size: 1.4rem;
          width: 9rem;
          height: 3.8rem;
          box-shadow: 0px 5px 12px grey;
          background-color: #8ab93f;
          // margin-left: 2.6rem;
        }

        .paygo-btn {
          font-size: 1.4rem;
          width: 9rem;
          height: 3.8rem;
          box-shadow: 0px 5px 12px grey;
          background-color: #feae01;
          margin-left: 2.5rem;
        }

        .quote-button-container {
          display: flex;
          justify-content: center;
          padding: 20px;

          .close-btn,
          .export-btn {
            box-shadow: 0px 5px 12px grey;
            background-color: #feae01;
          }

          .close-btn {
            width: 7rem;
          }
        }
      }

      .quote-table-box {
        box-shadow: 0.5px 1px 5px 3px grey;
      }

      .quote-table-box {
        background-color: white;
        padding: 1rem;

        .quote-button-container {
          display: flex;
          justify-content: center;
        }

        .table-wrapper {
          max-height: 400px;
        }

        thead {
          background-color: #3e9ccc;
        }

        th {
          background-color: #3e9ccc;
          color: white;
          text-align: center;
          vertical-align: middle;
          position: sticky;
          top: 0;
        }

        td {
          white-space: nowrap;
          // width: 50%;
          text-align: center;
          vertical-align: middle;
          max-width: 90px;
        }

        tr:nth-child(even) {
          background-color: #d6eeb3;
        }

        tr th:first-child {
          border-left: 4px solid #3e9ccc;
        }

        tr th:last-child {
          border-right: 4px solid #3e9ccc;
        }

        tr td:first-child {
          border-left: 4px solid #3e9ccc;
        }

        tr td:last-child {
          border-right: 4px solid #3e9ccc;
        }

        tbody:last-child {
          border-bottom: 4px solid #3e9ccc;
        }

        tbody:first-child {
          margin-top: 0px;
        }

        tbody:before {
          content: "@";
          display: block;
          line-height: 0px;
          text-indent: -999999px;
        }
      }

      .emod-ques,
      .historical-claims {
        margin-top: 3rem;
      }

      .emod-ques {
        input[type="radio"] {
          width: 20px;
          height: 20px;
          display: grid;
          place-content: center;
          background-color: transparent;
          cursor: pointer;
        }

        input[type="radio"]:after {
          content: "";
          width: 0.65em;
          height: 0.65em;
          border-radius: 40%;
          transform: scale(0);
          transition: 2s transform ease-in-out;
          box-shadow: inset 1em 1em var(--form-control-color);
        }

        input[type="radio"]:checked:after {
          transform: scale(1);
          border: 1px solid #0763ee83;
          box-shadow: #0763ee83;
          transition: 2s transform ease-in-out;
        }

        .form-group {
          margin-bottom: 2px;

          label,
          input {
            font-size: 16px !important;
            color: black;
          }

          input {
            box-shadow: 0.5px 1px 4px grey;
          }
        }

        input[type="text"] {
          width: 100%;
          border-radius: 4px;
          border: 1px solid #fff;
          background: #fff;
          box-shadow: 0.5px 1px 4px grey;
        }

        #dateRate1 {
          background: none;
          border: none;
        }
      }

      .grey-table-row {
        position: relative;
        right: 7px;
      }

      #claimTable {
        .form-group {
          margin-bottom: 2px;

          label,
          input {
            font-size: 16px !important;
            color: black;
          }

          input {
            box-shadow: 0.5px 1px 4px grey;
          }
        }

        input[type="text"] {
          width: 100%;
          border-radius: 4px;
          border: 1px solid #fff;
          background: #fff;
        }

        .form-group {

          select,
          input {
            height: 35px;
            color: #2b2d31;
            font-size: 14px;
            padding-left: 5px;
          }

          label {
            -webkit-line-clamp: 1;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            font-size: 11px;
            color: #333;
            margin-bottom: 0;

            &.mandatory::after {
              content: "*";
              padding-left: 2px;
            }
          }

          p.errMsg {
            color: red;
            font-size: 11px;
          }
        }

        input {
          height: 36px;
          margin-bottom: "none";
          color: #2b2d31;
          font-size: 14px;
        }

        color: #919399;
        font-weight: "normal";
        border-collapse: separate;
        border-spacing: 20px 0px;

        .row-no-coverage {
          .no-coverage {
            width: 30%;
            margin-bottom: 20px;

            input {
              height: 12px;
              margin-bottom: 25px;
              margin-right: 5px;
            }
          }
        }
      }
    }

    //styling of review and pay page:
    #payBind {

      input,
      select {
        height: 40px;
      }

      .formTitle {
        margin-bottom: 37px;
      }

      #carrierName {
        font-size: 16px;
      }

      background-image: url("../images/backimage5.png");
      background-repeat: no-repeat;
      background-position-x: 95%;
      background-position-y: 95%;
      background-attachment: sticky;

      .row-formTitle {
        justify-content: space-between;

        .formTitle {
          margin-bottom: 0rem;
        }
      }

      .row-acord-preview-heading {
        justify-content: space-between;

        .col-acord-text {
          margin-left: 16px;
        }

        .col-acord-download {
          margin-left: 15px;
          padding-left: 36px;
        }

        #accordLink {
          color: #0973d5;
        }

        img {
          margin-right: 1rem;
        }
      }

      .row-acord-preview {
        margin-top: 1rem;
        justify-content: center;
        margin-bottom: 3.5rem;

        iframe {
          border: none;
          width: 78%;
          height: 30rem;
        }
      }

      .pdf-upload-placeholder {
        width: 78%;
        height: 30rem;
        margin-top: 2rem;
        justify-content: center;
        align-items: center;
        margin-bottom: 81px;

        .pdf-loader-gif {
          margin-left: 1.5rem;
        }
      }

      .row-tnc {
        margin-top: 1rem;
        font-size: 16px;
        color: #2c2e33;

        p {
          margin-top: 0.6rem;
        }

        .payPageItems2 {
          margin-left: 2rem;
        }

        .linkLike {
          margin-left: 0.5rem;
        }
      }
    }

    //styling for bind page
    #binderForm {
      .linkLike {
        margin-top: 60px;
        margin-bottom: 36px;
      }

      .btnGen {
        margin-bottom: 10px;
      }

      .col-sm-12 {
        .formTitle {
          margin-bottom: 1rem;
        }

        .row-binder-heading {
          padding-right: 10px;
          padding-left: 10px;
          justify-content: space-between;
        }

        .row-iframe {
          justify-content: center;
          width: 100%;

          .col-iframe {
            padding-left: 25px;
            padding-right: 25px;
            width: 100%;

            .acord {
              justify-content: flex-end;
              width: 95%;
              color: #0973d5;

              span {
                position: relative;
                top: 4px;
                margin-left: 0.5rem;
              }
            }

            .row-iframe-preview {
              width: 100%;
              justify-content: center;

              iframe {
                // margin-top: 2rem;
                border: none;
                width: 90%;
                height: 45rem;
              }
            }
          }
        }
      }
    }

    .class-desc-select {
      background-color: white;

      div {
        height: 35px;
        padding-top: 0;
        padding-bottom: 0;
      }

      .class-desc-select__indicator {
        align-items: center;
      }

      .class-desc-select__menu {
        height: fit-content;
        width: fit-content;
        max-width: 350px;
        min-width: -webkit-fill-available;
        min-width: -moz-available;

        .class-desc-select__menu-list {
          height: fit-content;
          max-height: 150px;

          .class-desc-select__option {
            display: flex;
            align-items: center;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: fit-content;
          }
        }
      }

      .class-desc-select__placeholder {
        margin: 0;
        position: unset;
        transform: none;
        height: fit-content;
      }

      .class-desc-select__control {

        // min-height: 35px;
        // border: 1px solid #ababab;
        // background: transparent;
        // border-radius: 4px;
        .class-desc-select__value-container {
          overflow: initial;

          .class-desc-select__single-value {
            height: fit-content;
          }
        }
      }
    }

    .locationName {
      span {
        font-size: 16px;
        font-weight: normal;
        color: #2b2d31;
      }
    }

    #extraInput {
      margin-top: 5px;
    }

    .check {
      margin-right: 0.3rem;
    }

    #newAddress {
      display: none;
      margin-left: 0;
    }

    .payPageItems {
      margin-left: 1rem;
    }

    .linkLike {
      position: relative;
      right: 0.55rem;
      // margin-top: 68px;
      // margin-bottom: 63px;
    }

    // // styl for binder
    .green {
      margin-left: 25%;
      font-size: 22px;
      font-weight: 600;
      color: #138f11;
      margin-bottom: 16px;

      p {
        margin: 0;
      }
    }

    .endForm {
      margin-left: 2rem;
    }
  }

  //styling for review and pay
  //paybind is the main container of review and pay page:
  // styles of side tab
  .newquote-sidebar {
    width: 23.5%;
    // max-width: 50%;
    padding-left: 0.2rem;
    background-color: #ffffff;
    margin-top: 1rem;
    padding-bottom: 2rem;
    margin-right: 1rem;

    // font-family: "Overpass", sans-serif;
    .nav-item {
      outline: none;
      border-radius: none;

      .tick {
        margin-right: 0.08rem;
        margin-top: 1rem;
        float: right;
      }

      .nav-link {
        border: none;
        margin-bottom: 1rem;
        color: #2c2e33;
        // font-size: 16px;
        font-weight: 800;
        margin-left: 0.5rem;

        &.active {
          color: #0068c9;
          border: none;
        }

        .sidebar-stagename {
          margin-left: 1.2rem;
        }

        &.sidebar-heading {
          margin-top: 30px;
          color: #6b6666;
        }

        .disabled {
          display: none;
        }
      }
    }
  }
}

@media only screen and (max-width: 1350px) {
  .newquote-sidebar {
    font-size: 0.8rem !important;
  }

  #undrQues {
    font-size: 0.8rem !important;
  }

  #cmprQts {
    font-size: 0.8rem !important;
  }

  #compareQuote {
    font-size: 0.8rem !important;
  }
}

@media only screen and (max-width: 992px) {
  .newquote-sidebar {
    padding-left: 0.2rem;
    background-color: #ffffff;
    margin-top: 0rem !important;
    padding-bottom: 0rem !important;
    margin-right: 0rem !important;
  }
}

@media only screen and (max-width: 750px) {
  .newquote-content {
    font-size: 0.8rem !important;
  }
}

#downloadable {
  width: 90%;
  margin: auto;
  padding-top: 20px;
  font-size: 14px;

  strong {
    font-size: 16px;
  }
}

#downloadable {
  .sectionHolder {
    padding: 20px;
    margin: 30px auto;
  }
}

.home-page {
  //styling of the new home page
  padding-top: 5rem !important;

  #banMain {
    img {
      width: 100%;
    }

    h2 {
      // font-family: "Merriweather", sans-serif;
      color: #2162ac;
      font-size: 50px;
      font-weight: 600;
      margin-top: 26px;
      margin-bottom: 16px;
    }

    h3 {
      color: #2f3237;
      font-size: 20px;
      line-height: 1.5;
      font-weight: normal;
      margin-bottom: 36px;
    }

    .btn {
      color: white;
      // font-family: "Rubik", sans-serif;
      font-size: 22px;
      font-weight: normal;
      border-radius: 7.2px;
      color: #fff;
      background-color: #ffd13f;
      border-color: #eea236;
      margin-bottom: 30px;
      box-shadow: 2px 4px 12px #eea236;
    }
  }
}

#itWorks {
  margin-right: 0px;
  margin-left: 0px;

  .row-3steps {
    // width: 100%;
    padding: 40px 0 40px 0;
    height: auto;
    background-color: #2162ac;

    // background-color: #2e3952;
    //3 steps logo
    .col-simplesteps {
      color: white;
      text-align: left;

      .simple-steps-wrapper {
        max-width: 300px;
      }

      h2 {
        text-align: left;
        font-size: 200px;
        margin-bottom: 0px;
        line-height: 1;
      }

      h3 {
        font-size: 44px;
        font-weight: 300;
        text-align: left;
      }

      img {
        margin: 1rem;
        position: relative;
        left: 15px;
        display: none;
      }

      .line-below-3 {
        width: 180px;
        height: 4px;
        opacity: 0.5;
        background-color: #ffffff;
        margin-bottom: 1rem;
        margin-top: 10px;
      }
    }

    //3-steps cards
    .col-simplesteps-cards {
      .card {
        width: 90%;
        color: white;
        background-color: transparent;

        .row {
          align-items: center;

          .col-card-image {
            height: auto;
            margin-right: 10px;

            .card-img {
              border: 0px !important;
              width: 97px;
              object-fit: contain;
              // img {
              //   margin-bottom: 35px;
              // }
            }
          }

          .col-card-text {
            height: auto;

            .card-body {
              text-align: left;

              .card-title {
                // font-family: "Rubik", sans-serif;
                font-size: 28px;
                font-weight: 500;
              }

              .card-text {
                margin-top: auto;
                // font-family: "Rubik", sans-serif;
                font-size: 20px;
                line-height: 1.5;
                font-weight: normal;
              }

              .learn-more {
                // font-family: Arial, Helvetica, sans-serif;
                color: #2ed359;
                font-size: 16px;
                font-weight: bold;
              }

              .learn-more-arrow {
                // font-size: 2rem;
                font-weight: bolder;
              }
            }
          }
        }
      }
    }
  }

  .row-our-mission {
    color: white;
    height: 420px;
    background-image: linear-gradient(to right,
        rgba(0, 0, 0, 0.4),
        rgba(0, 153, 255, 0.4) 100%);
    background-image: url("../images/home-mission-background.jpg");

    .col-md-12 {
      padding: 80px 80px 80px 120px;

      h1 {
        position: relative;
        right: 13px;
        // font-family: "Merriweather", sans-serif;
        font-size: 100px;
        font-weight: bold;
        margin-bottom: 15px;
      }

      h2 {
        // font-family: "Rubik", sans-serif;
        font-size: 24px;
        font-weight: normal;
        line-height: 1.5;
      }
    }
  }

  .row-everything-you-need {
    .col-md-12 {
      padding: 115px;

      h1 {
        color: #353a3f;
        // font-family: "Merriweather", sans-serif;
        font-weight: bold;
        font-size: 44px;
      }

      .card-row {
        .card {
          background-color: #f8f9fb;
          height: auto;
          margin: 20px;
          text-align: center;

          img {
            height: 80px;
            width: auto;
            margin-top: 40px;
            margin-bottom: 30px;
          }

          .card-title {
            color: #33333c;
            // font-family: "Rubik", sans-serif;
            font-size: 20px;
            line-height: 1.3;
            margin-bottom: 2rem;
          }
        }
      }
    }
  }

  .row-video {
    background-color: #e7f4ff;
    height: auto;
    justify-content: center;

    .col-md-12 {
      padding-left: 114px;
      padding-right: 114px;
      padding-top: 100px;

      h1 {
        color: #2c2e34;
        // font-family: "Merriweather", sans-serif;
        font-size: 44px;
        font-weight: bold;
        line-height: 1.27;
      }

      h2 {
        color: #2c2e34;
        font-weight: normal;
        font-size: 20px;
        line-height: 1.5;
        margin-bottom: 40px;
      }

      iframe {
        margin-bottom: 120px;
      }
    }
  }
}

#footerMain {
  // font-family: "Rubik", sans-serif;
  padding-top: 24px;
  padding-bottom: 20px;
  color: white;
  height: 4.75rem;
  // background-color: #2e3952;
  background-color: #183650;
  padding-left: 100px;
  padding-right: 100px;

  .row-1 {
    div:nth-of-type(6) {
      margin-right: 10px;
    }

    justify-content: space-between;

    ul {
      padding-left: 2px;
      list-style: none;
      font-size: 16px;
    }

    .col-img {
      img {
        height: 75px;
        width: 130px;
      }
    }

    .col {
      a {
        color: white;

        &:hover {
          text-decoration: none;
          color: white;
        }
      }
    }
  }

  .t-and-c-row {
    // opacity: 0.6;
    font-size: 13px;
    line-height: 2.23;

    .col-sm-2 {
      a {
        color: white;
      }
    }

    .dull {
      opacity: 0.6;
    }

    .back-to-top {
      margin-right: 10px;
    }
  }
}

@keyframes slideInFromLeft {
  0% {
    opacity: 0%;
    // transform: translateY(-10%);
  }

  100% {
    opacity: 100%;
    // transform: translateY(0);
  }
}

.specialAdminRow {
  td {
    vertical-align: middle !important;
  }
}

.quote-table {
  .paygo-eligibility-container {
    margin-inline: 2rem;
    font-size: 14px;
  }

  .paygo-and-peo-container {
    // transition: cubic-bezier(0.075, 0.82, 0.165, 1);
    animation: 1s ease-out 0s 1 slideInFromLeft;
  }

  .head-row {
    align-items: center;
    justify-content: space-between;
  }

  .hr1 {
    color: #2c2e33;
    font-size: 26px;
    font-weight: 500;

    &.quote-table-company-name {
      // font-family: "Overpass", sans-serif;
      font-size: 20px;
      font-weight: 600;
    }
  }

  .hr2 {
    color: #2c2e33;
    width: 70%;
    margin-top: 1rem;
    margin-bottom: 1rem;

    span {
      color: #5f636b;
    }
  }

  .hr3 {
    margin-bottom: 1.5rem;
    color: #2c2e33;
  }

  .body-row {
    margin-top: 2rem;
    justify-content: center;

    .table {
      margin-bottom: 7rem;
      position: relative;
      right: 1rem;
      border-collapse: separate;
      border-spacing: 25px 0px;

      thead {
        th {
          color: #2c2e33;
          position: relative;
          right: 0.6rem;
          font-size: 16px;
        }
      }

      tbody {
        font-size: 18px;

        td,
        th {
          width: 25%;
          border: 1px solid white;
          background-color: white;
          color: #2c2e33;
          font-size: 18px;
          font-weight: normal;
        }

        td {
          p {
            font-size: 22px;
            font-weight: 800;
            color: #1463ac;

            .best {
              font-size: 14px;
              margin-left: 1em;
              color: #038b00;
            }

            .higher {
              font-size: 14px;
              margin-left: 1rem;
              color: #2c2e33;
            }
          }

          input {
            margin: 0.5rem;
          }
        }
      }
    }
  }

  .admin_type_value {
    flex-basis: content;
  }
}

#resDrop,
#workDrop,
#quotesDrop,
#profDrop {
  position: relative;
  display: none;
  padding: 0;
}

#resDrop .navDrop,
#quotesDrop .navDrop,
#workDrop .navDrop,
#profDrop .navDrop {
  background: #ffffff;
  position: absolute;
  z-index: 1;
  text-align: left;
  width: 180px;
  top: 10px;
  border-radius: 6px;
  box-shadow: 5px 5px 20px #888888;
}

#quotesDrop .navDrop {
  right: 0px;
  padding: 10px;
}

#profDrop .navDrop {
  width: 200px;
  right: 0px;
}

#profDrop hr {
  margin-top: 0;
  margin-bottom: 0;
}

#profDrop .navDrop li {
  display: inline-block;
  padding: 15px;
  color: #095d98;
  font-size: 1.5rem;
  font-style: initial;
}

#resDrop .navDrop li,
#quotesDrop .navDrop li,
#workDrop .navDrop li {
  display: block;
  color: #095d98;
  padding: 10px;
}

#Dropresources:hover #resDrop,
#Dropworks:hover #workDrop,
#Dropquotes:hover #quotesDrop,
#Dropprofile:hover #profDrop {
  display: block;
}

#Dropprofile:hover #profDrop {
  display: block;
}

#resDrop .navDrop img,
#workDrop .navDrop img {
  width: 30px;
  height: 30px;
  margin-left: 10px;
}

.tab-content {
  // height: 600px;
  min-height: calc(100vh - 9rem);
  display: flex;
  flex-direction: column;
  width: 100%;
}

.tab-content-mobile {
  min-height: fit-content;
}

// .tab-content-login,
// .tab-content-compliance {
//   min-height: calc(100vh - 15.75rem);
// }
// .tab-content-quote {
//   min-height: calc(100vh - 9rem);
// }
#loginButton {
  margin-left: 1rem;
  margin-right: 1rem;
}

#formLogin {
  height: auto;
  width: 450px;
  background: #ffffff;
  margin: auto;
  position: relative;
  padding-top: 20px;
  transition: all 0.5s;
}

#formChange {
  width: 450px;
  background: #ffffff;
  margin: auto;
  position: relative;
  padding-top: 20px;
  transition: all 0.5s;
}

#formReset {
  // min-height: 390px;
  width: 450px;
  background: #ffffff;
  margin: auto;
  position: relative;
  padding-top: 20px;
  transition: all 0.5s;
}

#formReset,
#formLogin,
#formChange {
  border: 1px solid #f9f9f9;
}

#formReset:hover,
#formLogin:hover,
#formChange:hover {
  box-shadow: 0 10px 15px #888888;
  transition: all 0.5s;
}

#lgin {
  /* padding: 150px 0; */
  transition: all 0.5s;
  margin: auto;
}

#chngpwd,
#fgtpwd {
  margin: auto;
}

.forSmall {
  display: none;
}

#loader {
  position: fixed;
  background: rgba(255, 255, 255, 0.9);
  height: 100%;
  width: 100%;
  z-index: 9;
  top: 0;
  text-align: center;
  display: none;
}

#loader img {
  position: relative;
  width: 80px;
  top: 40%;
  margin: auto;
}

#formReset,
#formChange,
#formLogin {
  // margin-top: -16.5px;
  margin-bottom: 16px;

  .formTitle {
    font-size: 20px;
  }

  background-color: #e2f2ff;

  form {
    // margin-top: 10px;
    padding: 0 20px;
  }

  form input[type="password"],
  form input[type="email"],
  form input[type="text"] {
    width: 100%;
    font-size: 0.85em;
    border-radius: 4px;
    border: 1px solid white;
    background: white;
    color: #2b2d31;
    font-weight: "normal";
    height: 35px;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  button {
    height: 35px;
    width: 100%;
    border-radius: 5px;
    background-image: linear-gradient(to right, #10c03e, #0eaf84);
    box-shadow: none;
    border-color: transparent;
    color: white;
    font-size: 14px;
    font-weight: 800;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .formBottomLinks {
    // padding: 20px 20px 50px 20px;
    justify-content: space-between;

    a {
      color: #2c2e33;
    }
  }

  .inptSideText {
    color: #5f636b;
  }
}

.formBottomLinks {
  padding: 20px;
}

#changePassForm {
  input[type="text"] {
    width: 100%;
    font-size: 0.85em;
    border-radius: 4px;
    border: 1px solid white;
    background: white;
    color: #2b2d31;
    font-weight: "normal";
    height: 35px;
    margin-bottom: 20px;
    margin-top: 20px;
  }
}

#quoteMain {
  @include cmprQts;
  background-color: #e2f2ff;

  #contentHolder {
    table {
      position: relative;
      right: 32px;
    }
  }
}

#resDrop,
#workDrop {
  .navDrop {
    padding: 0px;
    margin-left: -4rem;
    margin-top: 3rem;

    li {
      color: #0068c9;
      font-size: 13px;

      img {
        margin-left: 0px;
        margin-right: 8px;
        padding-left: 0px;
      }
    }

    a:hover {
      text-decoration: none;
    }
  }
}

.cmprQts-buttons {
  margin-bottom: 10px;
  text-align: center !important;
  // margin-left: 14rem;
}

.btnGen {
  margin-bottom: 36px;
}

#uwPortal {
  .fixedHeader {
    thead>tr>th {
      position: sticky;
      top: -1px;
      z-index: 3;
      background-color: white;
    }

    tbody>tr>td {
      vertical-align: middle;
    }
  }

  display: flex;
  height: calc(100vh - 5.7rem);
  // overflow-x: hidden;
  // overflow-y: auto;
  width: 100%;

  .btn-sm {
    min-width: 60px;
    margin-bottom: 5px;
  }

  .custom-control-label::before {
    color: #fff;
    background-color: #e97d88;
    border-color: #e97d88;
  }

  .custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #28a745;
    background-color: #28a745;
  }

  .custom-switch .custom-control-label::after {
    background-color: #fff;
  }
}

.modal-full {
  width: 95%;
  max-width: none !important;
  height: 95%;

  iframe {
    min-height: 80vh;
  }
}

@media only screen and (max-width: 740px) {
  .new-navbar {
    .boxed-logo {
      height: 100px;
      padding: 15px;
    }
  }
}

@media only screen and (max-width: 580px) {
  .new-navbar {
    .boxed-logo {
      height: 75px;
      padding: 11px;
      margin-top: 30px;
    }
  }
}

@media only screen and (max-width: 480px) {
  .new-navbar {
    .boxed-logo {
      height: 60px !important;
      padding: 8px;
      margin-top: 35px;
    }
  }
}

@media only screen and (max-width: 420px) {
  .new-navbar {
    .boxed-logo {
      margin: 5px 10px;
      margin-left: 20px;
      margin-top: 35px;
    }
  }
}

@media only screen and (max-width: 380px) {
  .new-navbar {
    .pride-logo {
      margin-left: 1.5rem;
    }

    .insurecomp-logo {
      margin-right: 1rem;
    }

    .new-logo {
      height: 3rem;
    }
  }
}

@media only screen and (max-width: 360px) {
  .new-navbar {
    .boxed-logo {
      height: 50px !important;
      padding: 5px;
      margin: 5px 5px;
      margin-top: 40px;
      margin-left: 15px;
    }
  }
}

@media only screen and (max-width: 340px) {
  .new-navbar {
    .pride-logo {
      margin-left: 1.5rem;
    }

    .insurecomp-logo {
      margin-right: 1rem;
    }

    .new-logo {
      height: 2.5rem;
    }
  }
}

.dashboard-admin {
  .new-navbar {
    background-color: white;
    border-bottom-style: groove;
  }

  .tabs {
    .MuiTableSortLabel-root.MuiTableSortLabel-active {
      color: white;

      .MuiTableSortLabel-icon {
        color: white !important;
      }
    }

    .nav-item {

      a,
      .active {
        color: black;
      }

      .active {
        background-color: #faa558;
      }
    }
  }
}

.dropZonesContainer {
  padding: 0 25px;

  .customDropZone {
    .dropzone {
      text-align: center;
      padding: 20px;
      border: 2px dashed #363333;
      background-color: #fafafa;
      color: #8a5656;

      .dropZoneText {
        cursor: pointer;
      }
    }

    li {
      background: none;

      .fw-bold {
        cursor: pointer;
      }
    }
  }
}

.popup {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 100;
  top: 0;
  text-align: center;
  display: none;
  background: rgba(0, 0, 0, 0.6);
}

.popup-container {
  background-color: #23b4e8;
  border-radius: 15px;
  box-shadow: 2px 4px 12px black;
  position: absolute;
  width: 500px;
  left: 50%;
  top: 50%;
  padding: 20px;
  color: white;
  transform: translate(-50%, -50%);

  .header-text {
    font-weight: bold;
  }
}

.tab-list {
  border-bottom: 1px solid #ccc;
  padding-left: 0;
}

.tab-list-item {
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  text-transform: uppercase;
}

.tab-list-active {
  background-color: white;
  border: solid #ccc;
  border-width: 1px 1px 0 1px;
}

.class-desc-select__menu {
  width: 60vw !important;
  max-width: none !important;
}

.class-desc-select__option {
  width: auto !important;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.pink-header {
  color: #c0224d;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: bold;
  display: flex;
}

#company-name-link:hover {
  text-decoration: underline;
}

.home-landing-page {
  overflow-y: hidden;

  .btn {
    background-color: #f8af16;
  }

  // @media only screen and (min-width: 2000px) {
  //   .why-us {
  //     .row-2 {
  //       .row {
  //         padding-top: 120px !important;
  //         .col {
  //           .custom-card {
  //             height: 300px !important;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
  // @media only screen and (min-width: 1700px) {
  //   .why-us {
  //     .row-2 {
  //       .row {
  //         padding-top: 100px !important;
  //         .col {
  //           padding-top: 30px !important;
  //           padding-bottom: 30px !important;
  //           .custom-card {
  //             height: 250px !important;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
  @media only screen and (min-width: 1600px) {
    .testimonial {
      height: 1000px !important;

      .testimonial-text-container {
        font-size: 30px !important;
      }
    }

    .learn-more-about-us {
      height: 820px !important;
      font-size: 30px !important;

      button {
        font-size: 20px !important;
      }
    }

    .custom-card-text {
      font-size: 20px !important;
    }
  }

  @media only screen and (min-width: 1400px) {
    .why-us {
      .row {
        padding-top: 40px !important;
      }

      .row-2 {
        .custom-card {
          height: 17vh !important;
        }
      }
    }
  }

  @media only screen and (min-width: 1750px) {
    .why-us {
      .row-2 {
        height: 0 !important;
        padding: 0 !important;
        padding-bottom: 8% !important;
        height: max-content !important;
        background-repeat: no-repeat !important;
        // background-attachment: fixed;
        background-size: cover !important;
        background-position: center center !important;
      }
    }
  }

  @media only screen and (min-width: 1380px) {
    .why-us {
      .row-2 {
        height: 0 !important;
        padding: 0 !important;
        padding-bottom: 10% !important;
        height: max-content !important;
        background-repeat: no-repeat !important;
        // background-attachment: fixed;
        background-size: cover !important;
        background-position: center center !important;
      }
    }
  }

  @media only screen and (max-width: 1195px) {
    .custom-card-text {
      font-size: 13px !important;
    }
  }

  @media only screen and (max-width: 1100px) {
    .custom-card-image {
      padding-left: 15px !important;
    }

    .top-image {
      height: 700px !important;
      min-height: auto;
      padding: none;
    }

    // .top-image {
    //   height: auto !important;
    //   min-height: auto;
    //   padding: none;
    // }
    .logo-2 {
      width: 285px !important;
    }
  }

  @media only screen and (max-width: 992px) {
    .top-image {
      height: 975px !important;
      min-height: auto;
      padding: none;
    }
  }

  @media only screen and (max-width: 900px) {
    .play-button {
      height: 70px !important;
      width: 70px !important;
    }

    .vid-img-cover {
      margin-top: -69% !important;
    }

    .why-us {
      .row-2 {
        height: fit-content;
        background-image: none !important;
        background-color: #7f3e99 !important;
      }
    }
  }

  @media screen and (max-width: 520px) {
    .ready-for-whatever-comes-next {
      margin-top: 30px !important;
    }
  }

  @media only screen and (max-width: 510px) {
    .logo-1 {
      img {
        height: 272px !important;
      }
    }

    .logo-2 {
      img {
        height: 125px !important;
        margin-top: 20px;
      }
    }

    .play-button {
      height: 50px !important;
      width: 50px !important;
      margin-top: -66% !important;
    }

    .custom-card-image {
      padding-right: 15px !important;
    }

    .ready-for-whatever-comes-next {
      height: auto !important;
      font-size: 33px !important;
    }
  }

  @media only screen and (max-width: 1250px) {
    .ready-for-whatever-comes-next {
      font-size: 2.5rem !important;
    }
  }

  @media only screen and (max-width: 1000px) {
    .ready-for-whatever-comes-next {
      font-size: 2.1rem !important;
    }
  }

  .aquired-by {
    margin-top: 16px;
    margin-left: 62px;
    width: 40vw;
    height: 52px;
    /* UI Properties */
    text-align: left;
    // font: "Open Sans";
    letter-spacing: 0px;
    font-size: 1.3rem;
    color: #ffffff;
    opacity: 1;
    line-height: 20px;
    font-family: "MontserratRegular" !important;
    margin-bottom: 40px;
  }

  @media only screen and (max-width: 1400px) {
    .aquired-by {
      font-size: 1rem !important;
      margin-top: 10px !important;
      margin-left: 52px !important;
    }
  }

  @media only screen and (max-width: 1250px) {
    .aquired-by {
      font-size: 0.9rem !important;
      margin-top: -5px !important;
      margin-left: 54px !important;
    }
  }

  @media only screen and (max-width: 900px) {
    .aquired-by {
      font-size: 0.9rem !important;
      margin-top: 30px !important;
      margin-left: 54px !important;
    }
  }

  @media only screen and (max-width: 700px) {
    .aquired-by {
      font-size: 0.8rem !important;
      margin-top: 30px !important;
      margin-left: 54px !important;
    }
  }

  @media only screen and (max-width: 450px) {
    .aquired-by {
      margin-left: auto !important;
      margin-right: auto !important;
      width: 90% !important;
      word-break: break-word !important;
      font-size: 15px !important;
    }

    .custom-card-image {
      padding-right: 5px !important;
    }

    .ready-for-whatever-comes-next {
      height: auto !important;
      font-size: 30px !important;
      margin-left: 20px !important;
    }

    .vid-img-cover {
      margin-top: -73% !important;
    }

    .learn-more-about-us {
      .form-col {
        width: auto !important;
        margin-inline: 20px !important;
      }
    }
  }

  @media only screen and (max-width: 470px) {
    .custom-card-image {
      padding-left: 5px !important;
    }
  }

  @media only screen and (max-width: 480px) {
    .fixed-ball {
      display: none;
    }
  }

  @media only screen and (max-width: 420px) {
    .ready-for-whatever-comes-next {
      height: auto !important;
      font-size: 25px !important;
      word-break: break-word !important;
      width: 90% !important;
    }

    .btn {
      text-align: center;
      // margin-left: 50% !important;
      transform: translate(-17%, 0);
    }
  }

  .custom-card {
    width: 90%;
    height: 20vh;
    background-color: white;
    border-radius: 10px;
    display: flex;
    align-items: center;

    .row-custom-card {
      height: 78px;
      display: flex;
      width: 100%;

      // padding-left: 55px;
      // padding-right: 55px;
      .custom-card-image {
        padding-left: 50px;
        // width: 72px;
        padding-right: 44px;
      }

      .custom-card-text {
        font-size: 1.2rem;
        // width: 384px;
        word-break: break-word;
        font-family: "MontserratMedium" !important;
      }
    }
  }

  .row-1 {
    height: 90px;
    margin-bottom: 84px;

    .horizontal-continer {
      width: 100%;

      hr {
        width: 50px;
        height: 0px;
        border: 3px solid #ff9900;
        opacity: 1;
      }
    }

    .our-services-text,
    .why-us-text,
    .learn-text {
      font-family: "MontserratMedium" !important;
      width: 100%;
      height: 48px;
      text-align: center;
      font-size: 34px;
      letter-spacing: 0px;
      color: #fff;
      opacity: 1;
    }
  }

  .top-image {
    top: 0px;
    left: 0px;
    opacity: 1;
    max-width: 100% !important;
    height: 0;
    padding: 0;
    padding-bottom: 50%;
    margin-top: -40px;
    background-color: #1c3464 !important;
    position: relative;
    background-position: left top;
    background-image: linear-gradient(180deg,
        #1c3464 0%,
        rgba(28, 52, 100, 0.9) 100%),
      url(https://www.fourth.com/en-au/wp-content/uploads/sites/3/2019/09/Hero-Referral-Program-1.jpg) !important;
    background-size: cover;

    // background-repeat: no-repeat !important;
    // background-attachment: fixed;
    // background-size: cover !important;
    // background-position: center center !important;
    .et_pb_bottom_inside_divider {
      display: block;
      height: 100%;
      position: absolute;
      pointer-events: none;
      width: 100%;
      left: 0;
      right: 0;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIzMDBweCIgdmlld0JveD0iMCAwIDEyODAgMTQwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9IiNGRkZGRkYiPjxwYXRoIGQ9Ik0wIDE0MGgxMjgwQzU3My4wOCAxNDAgMCAwIDAgMHoiIGZpbGwtb3BhY2l0eT0iLjMiLz48cGF0aCBkPSJNMCAxNDBoMTI4MEM1NzMuMDggMTQwIDAgMzAgMCAzMHoiIGZpbGwtb3BhY2l0eT0iLjUiLz48cGF0aCBkPSJNMCAxNDBoMTI4MEM1NzMuMDggMTQwIDAgNjAgMCA2MHoiLz48L2c+PC9zdmc+);
      background-size: 100% 300px;
      bottom: 0;
      height: 300px;
      z-index: 1;
      transform: scale(-1, 1);
    }

    .first-row {
      padding-top: 15px;
    }

    .ready-for-whatever-comes-next {
      font-family: "MontserratMedium" !important;
      margin-top: -60px;
      margin-left: 50px;
      width: 45vw;
      height: 120px;
      text-align: left;
      font-size: 3.1rem;
      letter-spacing: 0px;
      color: #ffffff;
      line-height: initial;
      opacity: 1;
      word-break: break-word;
    }

    @media only screen and (min-width: 1800px) {
      .ready-for-whatever-comes-next {
        font-size: 3.5rem !important;
        margin-top: -380px !important;
        margin-left: 60px !important;
      }
    }


    .btn {
      margin-top: 1rem;
      width: 207px;
      height: 40px;
      margin-left: 50px;
      color: white;
    }
  }

  .our-services {
    margin-top: 52px;

    .row-2 {
      .col {
        display: flex;
        align-items: center;
        justify-content: center;

        .card {
          width: 392px;
          height: 417px;
          border-top: none;
          box-shadow: none;
          transition: width 2s height 2s;
          transition-timing-function: ease-in;

          .card-img-container {
            padding-inline: 62px;
          }

          margin-bottom: 60px;

          .card-body {
            display: flex;
            justify-content: center;
            align-items: center;

            .card-text {
              width: 296px;
              height: 64px;
              text-align: center;
              font-size: 1.2rem;
              font-family: "MontserratMedium" !important;
              letter-spacing: 0px;
              color: #4a5456;
              opacity: 1;
            }
          }
        }
      }
    }
  }

  .our-services .row-2 .col .card:hover {
    width: 417px;
    height: 420px;
  }

  .why-us {
    // margin-top: 110px;
    background-color: #f4f4f4;
    padding-top: 20px;

    .row-2 {
      background-color: #7f3e99;
      height: auto;
      padding-top: 10px;
      padding-bottom: 120px;
      background-position: center center !important;
      background-repeat: no-repeat !important;
      // background-attachment: fixed !important;
      background-size: cover !important;

      .col {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 15px;
        padding-bottom: 15px;
      }
    }
  }

  .unique-about-us {
    .row-2 {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;

      // height: 650px;
      .play-vid-container {
        width: 50%;
        height: 450px;

        iframe {
          height: 100%;
          width: 100%;
        }

        .vid-img {
          opacity: 1;
        }

        .vid-img-cover {
          opacity: 1.63;
          margin-top: -65%;
          // transform: translate(-50%, -60%);
        }

        .play-button {
          margin-top: -55%;
          margin-left: 50%;
          transform: translate(-50%, -60%);
          opacity: 1;
        }
      }
    }

    .row-1 {
      margin-top: 60px;
    }

    margin-bottom: 60px;
  }

  .learn-more-about-us {
    background-repeat: no-repeat !important;
    // background-attachment: fixed;
    background-size: cover !important;
    background-position: center center !important;

    .row-1 {
      margin-bottom: 50px !important;
      text-align: left;
    }

    .form-col {
      margin-left: auto;
      margin-right: auto;
      width: 444px;

      input,
      .learn-comment {
        margin-top: 12px;
        margin-bottom: 12px;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 24px;
        width: 100%;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: inset 1px 0px 6px #00000029;
        border: 1px solid #e1e1e1;
        border-radius: 10px;
        opacity: 1;
        font-family: "MontserratMedium" !important;
        color: #080808;
      }

      .btn-container {
        display: flex;
        justify-content: end;

        .btn {
          color: white;
          width: 126px;
          margin-top: 10px !important;
        }
      }
    }
  }

  .testimonial {
    margin-top: 63px;
    width: auto;
    height: 508px;
    bottom: 70px;
    right: 0px;
    background-repeat: no-repeat !important;
    // background-attachment: fixed;
    background-size: cover !important;
    background-position: center center !important;

    .row {
      height: 100%;

      .quotation-mark {
        width: 162px;
        height: 162px;
      }

      [class*="col-"] {
        display: flex;
        justify-content: center;
        align-items: center;

        .testimonial-text-container {
          text-align: left;
          width: 100%;
          color: #ffffff;
          font-size: 16px;

          .italic-text-comp {
            color: #d8d8d8;
            margin-bottom: 0px;
          }

          .italic-text {
            width: 80% !important;
          }
        }
      }

      .large-col {
        padding: 50px;
      }
    }
  }

  .login-container {
    position: absolute;
    display: flex;
    width: 100%;
    height: 35px;
    background-color: #ffffff;
    top: 0px;
    left: 0px;
    align-items: center;
    justify-content: end;

    // color: #000000;
    .login-logout-link {
      margin-right: 8rem;
    }

    .dropdown-toggle {
      font-weight: 900;
    }

    a {
      color: rgb(0, 0, 0);
      cursor: pointer;
      font-weight: 900;
      font-size: large;
      margin-right: 30px;
    }

    a:hover {
      color: #021e35;
      text-decoration: underline;
    }
  }

  .fixed-ball {
    position: fixed;
    z-index: 1;
    right: 20px;
    bottom: 20px;
    width: 96px;
    height: 96px;
    border-radius: 57px;
    background-color: #f8af16;
    font-size: 12px;
    color: white;
    text-align: center;
    padding-top: 25px;
    cursor: pointer;
    animation-duration: 2s;
    animation-name: slidein;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
    box-shadow: none;
  }
}


.home-landing-page .learn-more-about-us .row-1 {
  margin-bottom: -30px !important;
  text-align: left;
}



@media only screen and (max-width: 1200px) {
  .home-landing-page .our-services .row-2 .col .card .card-body .card-text {
    font-size: 1rem !important;
  }

  .home-landing-page .row-1 .why-us-text {
    font-size: 2rem !important;
  }

  .row-1 {

    .our-services-text,
    .why-us-text,
    .learn-text {
      font-size: 2rem !important;
    }
  }
}

@media screen and (min-width: 1450px) {
  .home-landing-page .top-image .btn {
    margin-top: 1rem !important;
    height: 45px;
    font-size: 20px;
    width: 220px;
  }
}

@media screen and (max-width: 1450px) {
  .home-landing-page .top-image .btn {
    margin-top: -1rem !important;
  }
}

@media screen and (max-width: 1250px) {
  .home-landing-page .top-image .btn {
    margin-top: -2rem !important;
  }
}

@media screen and (max-width: 1250px) {
  .home-landing-page .top-image .btn {
    margin-top: -2rem !important;
  }
}

@media screen and (max-width: 666px) {
  .home-landing-page .top-image .btn {
    margin-top: 0.3rem !important;
  }

  .row-1 {

    .our-services-text,
    .why-us-text,
    .learn-text {
      font-size: 1.5rem !important;
    }
  }
}

@keyframes slidein {
  from {
    width: 96px;
    height: 96px;
    font-size: 12px;
    box-shadow: none;
  }

  to {
    width: 120px;
    height: 120px;
    font-size: 17px;
    box-shadow: 2px 2px 5px grey, -2px -2px 5px grey;
  }
}

@media screen and (max-width: 550px) {
  [class*="bannerColumn"] {
    font-size: 15px !important;
  }

  [class*="boldText"] {
    font-size: 15px !important;
  }
}

@media screen and (max-width: 400px) {
  [class*="bannerColumn"] {
    min-height: 400px !important;
  }

  [class*="boldText"] {
    font-size: 20px !important;
  }
}

.MuiTableSortLabel-root {
  color: white !important;
}

.MuiTableSortLabel-root:hover {
  color: #ff9900 !important;
}

.MuiTableSortLabel-active {
  font-weight: 700 !important;
  color: white !important;

  .MuiTableSortLabel-icon {
    color: white !important;
  }
}

.navbarcoloring {
  background-color: #ffffff;
}

.navwidth {
  width: 100% !important;
}

@media only screen and (max-width: 992px) {

  #carrier,
  #Annaulprem,
  #claims,
  #totalincurred {
    display: inline-flex;
    max-width: 50%;
    flex-direction: column;
  }
}

#peo-header_in_pdf {
  color: #ff5a54 !important;
  font-size: 28px;

  font-weight: 600;
  margin-left: 400px !important;
  margin-bottom: 30px !important;
  margin-top: 27px !important;
  width: 410px !important;
}

//clientsuta
@media only screen and (min-width: 1600px) {
  #clientsuta {
    margin-top: 23px !important;
  }
}

@media only screen and (max-width: 1149px) and (min-width: 992px) {
  #clientsuta {
    margin-top: 9px !important;
  }
}

@media only screen and (min-width: 1150px) and (max-width: 1349px) {
  #clientsuta {
    margin-top: 23px !important;
  }
}

//requestedsuta
@media only screen and (max-width: 1349px) and (min-width: 992px) {
  #requestedsuta {
    margin-top: 9px !important;
  }
}

@media only screen and (min-width: 2024px) {
  #requestedsuta {
    margin-top: 26px !important;
  }
}

//requestedWc
@media only screen and (max-width: 1349px) and (min-width: 992px) {
  #requestedWc {
    margin-top: 9px !important;
  }
}

@media only screen and (min-width: 2045px) {
  #requestedWc {
    margin-top: 23px !important;
  }
}

@media only screen and (max-width: 776px) {
  #dateRate1 {
    // padding-left: 107px !important;
    display: flex;
    justify-content: center;
  }

  .quotePageTableContent {
    font-size: 0.6rem !important;
  }

  .BindableQuoteContent {
    font-size: 0.9rem !important;
  }

  .Whyus__coloumn {
    flex-direction: column !important;
  }
}

@media only screen and (max-width: 550px) {
  .export-btn {
    font-size: 0.69rem !important;
  }

  .peo-btn,
  .paygo-btn {
    font-size: 0.9rem !important;
  }
}

@media only screen and (max-width: 976px) {
  .locationbreakcss {
    width: 100% !important;
    justify-content: left !important;
  }
}

@media only screen and (max-width: 700px) {
  .affinitylogo {
    width: 100% !important;
    justify-content: center !important;
    align-items: center !important;
    margin-top: 25px !important;
    height: 80px;
  }
}

@media only screen and (max-width: 700px) {
  .hotscheduleslogo {
    width: 100% !important;
    justify-content: center !important;
    align-items: center !important;
  }
}

// @media only screen and (max-width: 200px) {
//   .hotscheduleslogowidth {
//     width: 100% !important;
//     justify-content: center !important;
//     align-items: center !important;
//     padding: 20px 37px 0px 35px !important;
//     height: 100% !important;
//   }
// }

@media only screen and (max-width: 700px) {
  .question-list-container {
    width: auto !important;
  }
}

@media only screen and (max-width: 700px) {
  .paygoandpeo {
    // padding-right: 50px;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 1400px) {
  .logoreduce1 {
    height: 80px !important;
  }

  .logo-1 {
    width: 20% !important;
  }

  .logoreduce {
    height: 54px !important;
  }
}

@media only screen and (min-width: 1700px) {
  .logoreduce1 {
    height: 100px !important;
  }

  .logo-1 {
    width: 17% !important;
  }

  .logoreduce {
    height: 62px !important;
  }

  .logo-2 {
    width: 25% !important;
  }
}

@media only screen and (min-width: 1800px) {
  .logoreduce1 {
    height: 110px !important;
  }

  .logo-1 {
    width: 15% !important;
  }

  .logo-2 {
    width: 20% !important;
  }
}

@media only screen and (max-width: 1400px) {
  .logoreduce1 {
    height: 80px !important;
  }

  .logoreduce {
    height: 54px !important;
  }
}

@media only screen and (max-width: 1320px) {

  // .logoreduce1 {
  //   height: 70px !important;
  // }
  .logo-1 {
    width: 24% !important;
  }
}

@media only screen and (max-width: 1261px) {
  .logoreduce1 {
    height: 65px !important;
  }

  .logo-1 {
    width: 24% !important;
  }
}

@media only screen and (max-width: 1210px) {
  .logoreduce1 {
    height: 60px !important;
  }

  .logo-1 {
    width: 26% !important;
  }
}

@media only screen and (max-width: 1000px) {
  .logo-1 {
    width: 30% !important;
  }

  .logoreduce {
    height: 50px !important;
  }
}

@media only screen and (max-width: 850px) {
  .logo-1 {
    width: 36% !important;
  }
}

@media only screen and (min-width: 775px) {
  .bringingcolumn {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
}

@media only screen and (max-width: 775px) {
  .logo-1 {
    width: 38% !important;
  }
}

@media only screen and (max-width: 720px) {
  .logoreduce {
    height: 50px !important;
  }

  .logo-1 {
    width: 40% !important;
  }
}

@media only screen and (max-width: 630px) {
  .logoreduce1 {
    height: 65px !important;
  }

  .logo-1 {
    width: 45% !important;
  }
}

@media only screen and (max-width: 560px) {
  .logoreduce1 {
    height: 60px !important;
  }

  .logoreduce {
    height: 50px !important;
  }
}

@media only screen and (max-width: 520px) {
  .bringingcolumn {
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
    padding: 0px !important;
  }

  .logoreduce {
    margin-top: 75px;

    height: 50px !important;
  }
}

.button_color {
  background-color: #faa541 !important;
}

@media only screen and (max-width: 992px) {
  .sourceDropDown {
    display: flex;
    flex-direction: column !important;
  }
}

.adminPercent-container {
  box-shadow: 0.5px 1px 4px grey;
  border: 0.3px gre solid;

  .adminPercent-sign {
    background: white;
    width: 27px;
    border-radius: 3px;
    /* height: 100% !important; */
    border-left: 0.3px grey solid;
    display: flex;
    align-items: center;
    /* box-shadow: 0.5px 1px 4px grey; */
    justify-content: center;
  }

  input {
    box-shadow: none !important;
  }
}

.model-download {
  width: 60%;
  height: 100%;
}

.select-type-salesforce-dashboard {
  height: 35px;
  color: black;
  padding-left: 5px;
  box-shadow: 0.5px 1px 4px grey;
  border: none;
  margin-bottom: 10px;

  option {
    padding: 10px;
  }
}

@media only screen and (max-width: 460px) {
  #formLogin {
    width: 100vw !important;
  }
}

nav {
  background-color: #91cfa1;
  height: 50px;
  padding: 0 16px;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  /* fade in checked menu */
}

nav .m-menu__checkbox {
  display: none;
}

nav label.m-menu__toggle {
  cursor: pointer;
}

nav .m-menu {
  position: fixed;
  top: 0;
  left: 0;
  max-width: 360px;
  width: calc(100vw - 30px);
  // height: 100vh;
  height: 100%;
  // height: calc(var(--vh, 1vh) * 100);
  -moz-transform: translate3d(-450px, 0, 0);
  -o-transform: translate3d(-450px, 0, 0);
  -ms-transform: translate3d(-450px, 0, 0);
  -webkit-transform: translate3d(-450px, 0, 0);
  transform: translate3d(-450px, 0, 0);
  -moz-transition: transform 0.35s;
  -o-transition: transform 0.35s;
  -webkit-transition: transform 0.35s;
  transition: transform 0.35s;
  z-index: 12;
  overflow: hidden;
  background-color: #fff;
}

nav .m-menu__overlay {
  background-color: rgba(103, 103, 103, 0.5);
  position: absolute;
  top: 0;
  width: 100%;
  bottom: 0;
  z-index: 12;
  display: none;
}

nav .m-menu__header {
  padding-left: 0px;
  height: 0px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
}

nav .m-menu__header span {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  width: 100%;
}

nav .m-menu .m-menu {
  -moz-transform: translate3d(480px, 0, 0);
  -o-transform: translate3d(480px, 0, 0);
  -ms-transform: translate3d(480px, 0, 0);
  -webkit-transform: translate3d(480px, 0, 0);
  transform: translate3d(480px, 0, 0);
}

// nav .m-menu ul {
//   height: 100%;
//   overflow-y: auto;
// }

// nav .m-menu ul li a,
// .m-menu ul li label {
//   display: block;
//   text-align: left;
//   padding: 0 15px;
//   line-height: 47px;
//   text-decoration: none;
//   color: #333;
//   cursor: pointer;
//   font-size: 1rem;
//   border-bottom: 1px solid #e8e8e8;
//   position: relative;
// }

// nav .m-menu ul li label.a-label__chevron::after {
//   content: "";
//   position: absolute;
//   display: inline-block;
//   height: 10px;
//   width: 10px;
//   border-color: #333;
//   border-style: solid;
//   border-width: 1px 1px 0 0;
//   -moz-transform: rotate(45deg);
//   -o-transform: rotate(45deg);
//   -ms-transform: rotate(45deg);
//   -webkit-transform: rotate(45deg);
//   transform: rotate(45deg);
//   top: 50%;
//   margin-top: -5px;
//   right: 16px;
// }

// nav .m-menu ul li .-invisible {
//   border-bottom: 0;
// }

nav .m-menu .m-menu label.m-menu__toggle {
  display: flex;
  border-bottom: 0;
  padding: 0;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

nav .m-menu__checkbox:checked~.m-menu__overlay {
  display: block;
}

nav .m-menu__checkbox:checked~.m-menu {
  -moz-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.m-menu__toggle {
  padding-left: 25px;
}

.peo-header {
  color: #ff5a54 !important;
  font-size: 28px;
  margin-right: 1.5%;
  margin-top: 1%;
  font-weight: 600;
}

.accord-toolbar {
  padding: 10px;
  display: flex;
}

.accord-container {
  margin-top: 10px;
  border: 1px solid #fff;
  box-shadow: 0.5px 1px 4px grey;
  background-color: #f1f1f1;
}

.start_new_quote_btn {
  color: #c0224d;
}

@media only screen and (max-width: 281px) {
  .home-landing-page .testimonial .row .large-col {
    padding: 33px !important;
  }
}

@media only screen and (max-width: 900px) {
  .Dashboard__menucontent {
    padding: 6px;
    padding-left: 4px;
  }

  .logout_menu {
    padding: 5px;
    padding-left: 10px;
  }
}

#quoteGenetatepage {
  background-color: #e5f3fe;
}

.Header_QuoteTable {
  color: white !important;
  background-color: #589aca;
  // text-align: left !important;
}

// #quoteGenerate tbody tr {
//   text-align: center !important;
// }

#quoteGenerate th {
  padding: 0px 0px 0px 2px !important;
  border: 0.5px solid #dee2e6;
}

#quoteGenerate td {
  padding: 6px !important;
  border: 0.5px solid #dee2e6;
}

#quoteGenerate tbody tr:nth-child(even) {
  background-color: #dbebbb;
}

#quoteGenerate tbody tr:nth-child(odd) {
  background-color: #ffffff;
}

#quoteGenerate tbody td {
  vertical-align: middle !important;
}

.ExporttoPdf_button {
  margin: auto;
  display: flex;
  background-color: #fba441;
  color: white;
  border-radius: 5px;
  font-size: 1.5rem;
  box-shadow: 2px 2px 5px rgba(236, 226, 226, 0.2);
}

.Submission-uw-dashboard {
  tbody tr:first-child td:first-child {
    background-image: url("../images/filter_excel.png");
    background-repeat: no-repeat;
    background-size: 70% 70%;
    top: 5px !important;
    left: 4px !important;
    position: relative !important;
  }
}

.sub_unberwritter_tab {
  width: 50% !important;
}

.pre-underwriter {
  tbody tr:first-child td:first-child {
    background-image: url("../images/filter_excel.png");
    background-repeat: no-repeat;
    background-size: 70% 70%;
    top: 5px !important;
    left: 4px !important;
    position: relative !important;
  }
}

.sub_unberwritter_tab tbody tr:first-child td:first-child {
  background-image: none;
  top: 0 !important;
  left: 4px !important;
  position: none !important;
}

#pre_campaigned_subtab tbody tr:first-child td:first-child {
  background-image: none;
  top: 0 !important;
  left: 4px !important;
  position: none !important;
}

#pre_engaged_subtab tbody tr:first-child td:first-child {
  background-image: none;
  top: 0 !important;
  left: 4px !important;
  position: none !important;
}

.sub_unberwritter_tab {
  font-size: 1rem !important;
  font-family: "Montserrat";
}

.sub_unberwritter_tab thead tr th {
  padding: 5px !important;
  font-family: "Montserrat";
}

.sub_unberwritter_tab tbody tr td {
  padding: 5px !important;
}

.sub_underwritter_pdfImage1,
.sub_underwritter_pdfImage2,
.sub_underwritter_pdfImage3 {
  width: 30px;
  height: 50px;
}

.sub_underwritter_pdfImage4 {
  width: 50px;
  height: 80px;
}

.sub_unberwritter_header {
  font-size: 0.9rem !important;
  font-family: "Montserrat";
}

.sub_underwritter_header_row1,
.sub_underwritter_header_row2,
.sub_underwritter_header_row3,
.sub_underwritter_header_row4,
.sub_underwritter_header_row5,
.sub_underwritter_rejected_button,
.sub_underwritter_hold_button,
.sub_underwritter_approved_button {
  font-size: 0.9rem !important;
  font-family: "Montserrat";
  text-align: center !important;
}

.sub_unberwritter_tab tbody tr td {
  text-align: center;
  font-size: 0.9rem !important;
  font-family: "Montserrat";
}

.sub_underwritter_approved_button,
.sub_underwritter_hold_button,
.sub_underwritter_rejected_button {
  color: #fff;
  border: none;
}

.sub_underwritter_approved_button {
  background-color: #008e1b;
}

.sub_underwritter_hold_button {
  background-color: #ffbd16;
}

.sub_underwritter_rejected_button {
  background-color: #cb2e3e;
}

// .sub_preunder_UW thead tr th,
// .sub_preunder_UW tbody tr td {
//   font-size: 1rem !important;
//   font-family: "Montserrat";
//   text-align: center !important;
// }

@media only screen and (max-width: 1600px) {

  .sub_underwritter_header_row1,
  .sub_underwritter_header_row2,
  .sub_underwritter_header_row3,
  .sub_underwritter_header_row4,
  .sub_underwritter_header_row5,
  .sub_underwritter_rejected_button,
  .sub_underwritter_hold_button,
  .sub_underwritter_approved_button {
    font-size: 0.87rem !important;
    font-family: "Montserrat";
    text-align: center !important;
  }

  .sub_underwritter_pdfImage1,
  .sub_underwritter_pdfImage2,
  .sub_underwritter_pdfImage3 {
    width: 25px;
    height: 45px;
  }

  .sub_underwritter_pdfImage4 {
    width: 45px;
    height: 70px;
  }

  // .sub_preunder_UW thead tr th,
  // .sub_preunder_UW tbody tr td {
  //   font-size: 0.9rem !important;
  //   font-family: "Montserrat";
  //   text-align: center !important;
  // }
}

@media only screen and (max-width: 1500px) {

  .sub_underwritter_header_row1,
  .sub_underwritter_header_row2,
  .sub_underwritter_header_row3,
  .sub_underwritter_header_row4,
  .sub_underwritter_header_row5,
  .sub_underwritter_rejected_button,
  .sub_underwritter_hold_button,
  .sub_underwritter_approved_button {
    font-size: 0.85rem !important;
    font-family: "Montserrat";
    text-align: center !important;
  }

  .sub_underwritter_pdfImage1,
  .sub_underwritter_pdfImage2,
  .sub_underwritter_pdfImage3 {
    width: 22px;
    height: 41px;
  }

  .sub_underwritter_pdfImage4 {
    width: 40px;
    height: 65px;
  }

  // .sub_preunder_UW thead tr th,
  // .sub_preunder_UW tbody tr td {
  //   font-size: 0.85rem !important;
  //   font-family: "Montserrat";
  //   text-align: center !important;
  // }
}

.Submission-uw-dashboard {
  font-family: "Montserrat";
}

@media only screen and (max-width: 1410px) {

  .sub_underwritter_header_row1,
  .sub_underwritter_header_row2,
  .sub_underwritter_header_row3,
  .sub_underwritter_header_row4,
  .sub_underwritter_header_row5,
  .sub_underwritter_rejected_button,
  .sub_underwritter_hold_button,
  .sub_underwritter_approved_button {
    font-size: 0.8rem !important;
    font-family: "Montserrat";
    text-align: center !important;
  }

  .sub_underwritter_pdfImage1,
  .sub_underwritter_pdfImage2,
  .sub_underwritter_pdfImage3 {
    width: 20px;
    height: 37px;
  }

  .sub_underwritter_pdfImage4 {
    width: 37px;
    height: 30px;
  }

  // .sub_preunder_UW thead tr th,
  // .sub_preunder_UW tbody tr td {
  //   font-size: 0.8rem !important;
  //   font-family: "Montserrat";
  //   text-align: center !important;
  // }
}

@media only screen and (max-width: 1300px) {

  .sub_underwritter_header_row1,
  .sub_underwritter_header_row2,
  .sub_underwritter_header_row3,
  .sub_underwritter_header_row4,
  .sub_underwritter_header_row5,
  .sub_underwritter_rejected_button,
  .sub_underwritter_hold_button,
  .sub_underwritter_approved_button {
    font-size: 0.72rem !important;
    font-family: "Montserrat";
    text-align: center !important;
  }

  // .sub_preunder_UW thead tr th,
  // .sub_preunder_UW tbody tr td {
  //   font-size: 0.72rem !important;
  //   font-family: "Montserrat";
  //   text-align: center !important;
  // }
  .sub_underwritter_pdfImage1,
  .sub_underwritter_pdfImage2,
  .sub_underwritter_pdfImage3 {
    width: 18px;
    height: 34px;
  }

  .sub_underwritter_pdfImage4 {
    width: 35px;
    height: 27px;
  }
}

.salesPersonTab,
.sub_tab1,
.sub_tab2,
.sub_tab3,
.sub_tab4 {
  width: 50% !important;
}

.salesPersonTab tbody tr td,
.sub_tab1 tbody tr td,
.sub_tab2 tbody tr td,
.sub_tab3 tbody tr td,
.sub_tab4 tbody tr td {
  text-align: center;
  font-size: 0.9rem !important;
  font-family: "Montserrat";
  width: 20% !important;
  white-space: nowrap;
}

.salesPersonTab thead tr td,
.sub_tab1 thead tr th,
.sub_tab2 thead tr th,
.sub_tab3 thead tr th,
.sub_tab4 thead tr th {
  text-align: center;
  font-size: 0.9rem !important;
  font-family: "Montserrat";
  width: 20% !important;
  white-space: nowrap;
}

@media only screen and (max-width: 1600px) {

  .salesPersonTab tbody tr td,
  .sub_tab1 tbody tr td,
  .sub_tab2 tbody tr td,
  .sub_tab3 tbody tr td,
  .sub_tab4 tbody tr td {
    text-align: center;
    font-size: 0.87rem !important;
    font-family: "Montserrat";
    width: 20% !important;
    white-space: nowrap;
  }

  .sub_tab1 thead tr th,
  .sub_tab2 thead tr th,
  .sub_tab3 thead tr th,
  .sub_tab4 tbody tr td {
    text-align: center;
    font-size: 0.87rem !important;
    font-family: "Montserrat";
    width: 20% !important;
    white-space: nowrap;
  }
}

@media only screen and (max-width: 1500px) {

  .salesPersonTab tbody tr td,
  .sub_tab1 tbody tr td,
  .sub_tab2 tbody tr td,
  .sub_tab3 tbody tr td,
  .sub_tab4 tbody tr td {
    text-align: center;
    font-size: 0.85rem !important;
    font-family: "Montserrat";
    width: 20% !important;
    white-space: nowrap;
  }

  .salesPersonTab thead tr td,
  .sub_tab1 thead tr th,
  .sub_tab2 thead tr th,
  .sub_tab3 thead tr th,
  .sub_tab4 tbody tr td {
    text-align: center;
    font-size: 0.85rem !important;
    font-family: "Montserrat";
    width: 20% !important;
    white-space: nowrap;
  }
}

@media only screen and (max-width: 1400px) {

  .salesPersonTab tbody tr td,
  .sub_tab1 tbody tr td,
  .sub_tab2 tbody tr td,
  .sub_tab3 tbody tr td,
  .sub_tab4 tbody tr td {
    text-align: center;
    font-size: 0.8rem !important;
    font-family: "Montserrat";
    width: 20% !important;
    white-space: nowrap;
  }

  .salesPersonTab thead tr td,
  .sub_tab1 thead tr th,
  .sub_tab2 thead tr th,
  .sub_tab3 thead tr th,
  .sub_tab4 tbody tr td {
    text-align: center;
    font-size: 0.8rem !important;
    font-family: "Montserrat";
    width: 20% !important;
    white-space: nowrap;
  }
}

@media only screen and (max-width: 1300px) {

  .salesPersonTab tbody tr td,
  .sub_tab1 tbody tr td,
  .sub_tab2 tbody tr td,
  .sub_tab3 tbody tr td,
  .sub_tab4 tbody tr td {
    text-align: center;
    font-size: 0.72rem !important;
    font-family: "Montserrat";
    width: 20% !important;
    white-space: nowrap;
  }

  .salesPersonTab thead tr td,
  .sub_tab1 thead tr th,
  .sub_tab2 thead tr th,
  .sub_tab3 thead tr th,
  .sub_tab4 tbody tr td {
    text-align: center;
    font-size: 0.72rem !important;
    font-family: "Montserrat";
    width: 20% !important;
    white-space: nowrap;
  }
}

.underwrittingcolor {
  background-color: #e2f2fe;
}

.MuiTableCell-paddingNone {
  display: none;
}

.companyDeleteButton {
  display: flex;
  margin-bottom: 40px;
}

@media only screen and (max-width: 992px) {
  .companyDeleteButton {
    margin-bottom: 40px !important;
    margin: 0 auto;
  }
}

.specialAdminRow {
  text-align: left;
  margin-bottom: 40px !important;
  margin-top: 50px !important;
  margin-left: 20px !important;
  // display: flex;

  width: 55% !important;
}

.Reminderpoints {
  margin-top: 3rem;
}

.specialAdminRow input {
  padding: 0.2rem;
}

.disableLastName,
.disableFirstName,
.disablePhoneNumber {
  background-color: rgb(192, 191, 191) !important;
}

#quoteGenetatepage {
  font-size: 1.1rem;
}

@media only screen and (max-width: 1800px) {
  #quoteGenetatepage {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 1700px) {
  #quoteGenetatepage {
    font-size: 0.95rem;
  }
}

@media only screen and (max-width: 1600px) {
  #quoteGenetatepage {
    font-size: 0.9rem;
  }
}

@media only screen and (max-width: 1600px) {
  #quoteGenetatepage {
    font-size: 0.85rem;
  }
}

@media only screen and (max-width: 1440px) {
  #quoteGenetatepage {
    font-size: 0.8rem;
  }
}

@media only screen and (max-width: 1385px) {
  #quoteGenetatepage {
    font-size: 0.72rem;
  }
}

@media only screen and (max-width: 1285px) {
  #quoteGenetatepage {
    font-size: 0.7rem;
  }
}

@media only screen and (max-width: 1245px) {
  #quoteGenetatepage {
    font-size: 0.65rem;
  }

  .newquote-content {
    width: 75% !important;
  }
}

@media only screen and (max-width: 1245px) {
  #quoteGenetatepage {
    font-size: 0.67rem;
  }
}

@media only screen and (max-width: 1100px) {
  #quoteGenetatepage {
    font-size: 0.64rem;
  }
}

@media only screen and (max-width: 1100px) {
  #quoteGenetatepage {
    font-size: 0.64rem;
  }

  .newquote-content {
    //global styling for everything in the light blue background

    width: 70% !important;
  }
}

.broker-dropdown__value-container {
  width: 320px;
}

.brokerErrorDisplay__value-container {
  width: 320px;
  border: 1.5px solid red;
}

.brokerPercentageError {
  border: 1px solid red;
  width: 60%;
  border-radius: 3px;
}

.brokerPercentage {
  border: none;
  width: 60%;
}

.greyout {
  background-color: rgb(194, 196, 197) !important;
}

#brokerFlow {
  input[type="radio"] {
    width: 18px;
    height: 18px;
    display: grid;
    place-content: center;
    background-color: transparent;
    cursor: pointer;
  }

  input[type="radio"]::after {
    content: "";
    width: 0.65em;
    height: 0.65em;
    border-radius: 40%;
    transform: scale(0);
    transition: 2s transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
  }

  input[type="radio"]:checked::after {
    transform: scale(1);
    border: 1px solid #0763ee83;
    box-shadow: #0763ee83;
    transition: 2s transform ease-in-out;
  }
}

.commisonQuestion {
  margin-bottom: 30px;
}

.select_broker_dropdown {
  margin-top: 20px;
}

.table-undrques {
  margin-top: 5px;
}

@media only screen and (max-width: 1100px) {
  .commisonQuestion {
    margin-top: 10px;
  }

  .broker_dropdown {
    padding-left: 14px;
    margin-top: 20px;
    margin-bottom: 5px;
  }

  #brokerFlow {
    margin-top: -25px !important;
  }
}

#BrokerId {
  height: 37px;
}

#overviewcontiner,
#Pricingcontiner,
#Acknowledgecontiner,
#acknoQuestioncontiner {
  display: flex;
}

#headercontiner {
  display: flex;
}

#Peoheader {
  font-size: 30px !important;
  color: #c91351;
  margin-left: 50px;
}

#Peoheadersub {
  font-size: 30px !important;
  color: grey;
}

#bannercontiner {
  display: flex;
  margin: 0px auto;
}

#bannerid {
  margin-left: 400px !important;
  margin-bottom: 30px !important;
  margin-top: 27px !important;
  width: 410px !important;
}

#overviewRow {
  font-size: 26px !important;
  color: grey;
  font-weight: 700;
  margin-left: 50px;
  margin-top: 50px;
  margin-bottom: 25px;
}

#overViewSubContainer {
  background-color: #e0f6fb;
  height: 83px;
  display: flex;

  margin-left: 50px;
  margin-right: 40px;
  width: 960px;
}

#Disclaimercontiner {
  width: 1010px;
  display: flex;
}

#overviewSub {
  font-size: 13.8px !important;
  margin-left: 50px;
  margin-top: 10px;
}

#Pricing {
  font-size: 26px !important;
  color: grey;
  font-weight: 700;
  margin-left: 50px;
  margin-top: 30px;
  margin-bottom: 45px;
}

#inputTableContainer {
  display: flex;
  margin-left: 115px;
  margin-right: 95px;
  width: 870px;
}

#inputNetratetable {
  margin: 0px auto;
  font-size: 14px !important;
}

#Acknowledge {
  color: grey;
  font-weight: 700;
  font-size: 26px !important;

  margin-left: 50px;
  margin-top: 45px;
  margin-bottom: 40px;
}

#Disclaimer {
  margin-left: 50px;
  font-size: 13.5px !important;
}

#subDisclaimer {
  font-size: 13.5px !important;
  font-weight: 800;
}

#subDisclaimer1 {
  font-size: 13.5px !important;
  font-weight: 100 !important;
}

#acknoQuestion {
  font-weight: 400;
  font-size: 14px !important;

  margin-left: 50px;
}

.pdfForm {
  font-weight: 400;
  font-size: 14px !important;
  margin-top: 35px;
  margin-left: 170px;
}

.physicalAddress {
  margin-top: 10px;
}

.compAndFein,
.compAndAddress,
.OwnerOfficeAndPropsedate {
  font-weight: 400;
  font-size: 14px !important;
  margin-top: 25px;
  margin-left: 140px;
}

.compAndAddress,
.OwnerOfficeAndPropsedate {
  margin-top: 4px !important;
}

.netRateformdetails {
  margin-bottom: 30px;
}

.twoPeopleImage,
.topImageContent {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width: 1600px) {
  .et_pb_image {
    width: 600px !important;
    height: 580px !important;
  }
}

@media only screen and (min-width: 1700px) {
  .et_pb_image {
    width: 720px !important;
    height: 640px !important;
  }
}

@media only screen and (min-width: 1800px) {
  .et_pb_image {
    width: 720px !important;
    height: 680px !important;
  }
}

@media only screen and (min-width: 2000px) {
  .et_pb_image {
    width: 790px !important;
    height: 770px !important;
  }
}

@media only screen and (max-width: 1440px) {
  .et_pb_image {
    width: 520px !important;
    height: 500px !important;
  }
}

@media only screen and (max-width: 1375px) {
  .et_pb_image {
    width: 440px !important;
    height: 420px !important;
  }
}

@media only screen and (max-width: 1220px) {
  .et_pb_image {
    width: 320px !important;
    height: 300px !important;
  }
}

@media only screen and (max-width: 1100px) {
  .et_pb_image {
    width: 390px !important;
    height: 350px !important;
    margin: 50px;
  }

  .twoPeopleImage,
  .topImageContent {
    display: flex;
    justify-content: center !important;
    margin-top: 90px;
    margin-left: 40px;
  }
}

@media only screen and (max-width: 670px) {
  .et_pb_image {
    width: 370px !important;
    height: 300px !important;
    margin-top: 15px;
  }
}

@media only screen and (max-width: 520px) {
  .et_pb_image {
    width: 350px !important;
    height: 300px !important;
    margin-top: 20px;
  }

  .twoPeopleImage,
  .topImageContent {
    display: flex;
    margin-left: 0px;
    justify-content: center !important;
    margin-top: 0px;
  }

  .lower-block {
    width: 100% !important;
  }
}

@media only screen and (max-width: 460px) {
  .et_pb_image {
    width: 300px !important;
    height: 280px !important;
    // margin-top: 20px;
  }
}

@media only screen and (max-width: 450px) {
  .et_pb_image {
    width: 290px !important;
    height: 270px !important;
    // margin-top: 80px;
  }
}

@media only screen and (max-width: 425px) {
  .et_pb_image {
    width: 310px !important;
    height: 290px !important;
    margin-top: 60px;
  }
}

@media only screen and (max-width: 415px) {
  .et_pb_image {
    width: 300px !important;
    height: 280px !important;
    margin-top: 55px;
  }

  nav .m-menu {
    width: 320px !important;
  }
}

@media only screen and (max-width: 370px) {
  .et_pb_image {
    width: 280px !important;
    height: 290px !important;
    margin-top: 88px;
  }
}

@media only screen and (max-width: 400px) {
  .requstedAdminRow {
    flex-wrap: wrap;
    justify-content: center;
  }

  .requestedAdminContent {
    padding-bottom: 25px;
  }
}

.row-no-margin {
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-top: 30px !important;
}


@media only screen and (max-width: 1270px) {
  .row-no-margin {
    margin-top: 0px !important;
  }
}


@media only screen and (min-width: 1500px) {
  .logoreducing {
    height: 148px !important;
  }
}

@media only screen and (min-width: 1600px) {
  .logoreducing {
    height: 160px !important;
  }
}

@media only screen and (min-width: 1700px) {
  .logoreducing {
    height: 170px !important;
  }
}

@media only screen and (min-width: 1800px) {
  .logoreducing {
    height: 180px !important;
  }
}

@media only screen and (min-width: 1900px) {
  .logoreducing {
    height: 190px !important;
  }
}

@media only screen and (min-width: 2000px) {
  .logoreducing {
    height: 205px !important;
  }
}

.logoreducing {
  height: 140px;
}

.e-verify {
  color: #00b6de;
}

.Free_breakdownBox {
  margin-left: 120px;
  margin-right: 990px;
}

#feinCheckYes,
#feinCheckNo,
#physicalAddressCheckYes,
#physicalAddressCheckNo,
#e-verifyCheckYes,
#e-verifyCheckNO {
  height: 22px !important;
  width: 22px !important;
}

.dashboard-dropdown-row {
  select {
    box-shadow: 0px 2px 1px -2px rgba(0, 0, 0, 0.174),
      0px 2px 2px 0px rgba(0, 0, 0, 0.174), 0px 1px 5px 0px rgba(0, 0, 0, 0.174) !important;
    border-radius: 4px !important;
    border: none !important;
    height: 30px !important;
  }
}

#companyprofilequestion {
  .tableQuesCol {
    width: 65%;
  }

  .tableAns {
    width: 17%;
  }
}

#companyproQuestPEOPaygo {
  width: 90% !important;

  .tableQuesCol {
    width: 58.5%;
  }
}

#companyproQuestPEOPaygo,
.tableQuesCol,
.tableAns {
  width: 15%;
}

.notes-row {
  padding-inline: 15px;
  margin-top: -15px;

  .form-group {
    width: 100%;
  }
}

.MuiFormControl-root,
.MuiTextField-root {
  border-bottom: 1.7px solid #10110f !important;
}

.acordSelectedList {
  ol {
    margin-left: -1rem !important;
    display: flex !important;
    padding-left: 0px !important;
    position: relative;
    left: -45px;
    bottom: -50px;
  }

  ol,
  li {
    padding: 5px 15px 5px 10px !important;
    margin-left: 12px;
    margin-bottom: -10px;
    font-size: 1rem;
    font-weight: 600;
    flex-direction: row !important;
  }
}

// .tabSelected {
//   font-weight: 900;
//   font-size: 1.1rem;
//   box-shadow: 1.5px 1.5px 4px;
//   margin: 0;
//   padding: 0;
//   background: whitesmoke;
// }
.TabNameSelected {
  border-radius: 2px;
  display: flex !important;
  flex-direction: row !important;
  background-color: whitesmoke;
  box-shadow: 1.5px 1.5px 4px;
  font-size: 1.2rem;
  font-weight: 900;
}

.MuiInput-underline:after {
  border-bottom: 2px solid #46ce74 !important;
}

.ChevronRight {
  position: absolute;
  right: 8%;
  width: 2.5%;
  bottom: 35%;
  z-index: 10;
  border: none;
  opacity: 0.5;
  border-radius: 50%;
  color: #312f2f;
  font-weight: bold;
  font-size: 30px;
  transition: 0.2s;
}

.ChevronLeft {
  position: absolute;
  left: 8%;
  width: 2.5%;
  bottom: 35%;
  z-index: 10;
  border: none;
  opacity: 0.5;
  border-radius: 50%;
  color: #312f2f;
  font-weight: bold;
  font-size: 35px;
  transition: 0.2s;
}

.ChevronLeft:hover {
  color: black !important;
  font-size: 35px;
  opacity: 0.9;
}

.ChevronRight:hover {
  color: rgb(37, 21, 21) !important;
  font-size: 35px;
  opacity: 0.9;
}

@media only screen and (max-width: 1600px) {
  .ChevronRight {
    // top: 78%;

    font-size: 30px;
    transition: 0.2s;
    width: 3%;
  }

  .ChevronLeft {
    // top: 78%;

    font-size: 30px;
    transition: 0.2s;
    width: 3%;
  }

  .ChevronLeft:hover {
    color: black !important;
    font-size: 35px;
  }

  .ChevronRight:hover {
    color: rgb(37, 21, 21) !important;
    font-size: 35px;
  }
}

@media only screen and (max-width: 1400px) {
  .ChevronRight {
    font-size: 28px;
    width: 4%;
  }

  .ChevronLeft {
    font-size: 28px;
    width: 4%;
  }

  .ChevronLeft:hover {
    color: black !important;
    font-size: 32px;
  }

  .ChevronRight:hover {
    color: rgb(37, 21, 21) !important;
    font-size: 32px;
  }
}

@media only screen and (max-width: 770px) {
  .ChevronRight {
    font-size: 25px;
    width: 5%;
  }

  .ChevronLeft {
    font-size: 25px;
    width: 5%;
  }

  .ChevronLeft:hover {
    color: black !important;
    font-size: 26px;
  }

  .ChevronRight:hover {
    color: rgb(37, 21, 21) !important;
    font-size: 26px;
  }
}

.btnFormFinal {
  border: 1px solid grey;
  border-radius: 5px;
  font-size: 14px;
  padding: 4px 5px 4px 5px;
}

.MuiInputBase-input,
.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated {
  font-size: 13px !important;
}

.accord-container-mobile {
  width: 15rem;
  height: 60px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-shadow: 0px 5px 12px grey;
  background-color: #faa541;
  font-size: 1rem;
  font-weight: bolder;
}

.spinner {
  width: 15rem;
  margin: auto;
  margin-top: 20px;
}

//Comments box styling
.comment-btn {
  border: 1px solid #ffbd16;
  border-radius: 5px;
  font-size: 20px !important;
  margin-left: 2px !important;
}

.dashboardCompanyName {
  color: #3c99f0;
  cursor: pointer;
}

.spinner-border {
  width: 1.5rem;
  height: 1.5rem;
}

.comment-btn:hover {
  border: 1px solid #ffffff;
  background-color: #ffbd16;
  color: #ffffff;
  border-radius: 5px;
}

.MuiDialogTitle-root {
  padding: 17px 85px !important;
}

.disabled_comment-btn {
  border: 1px solid #ffbd16;
  border-radius: 5px;
  font-size: 20px !important;
  margin-left: 2px !important;
}

.button-save-style {
  background-color: #427cbc;
  color: white;
  padding-top: 1px;
  padding-bottom: 1px;
  font-size: 0.72rem;
  box-shadow: rgba(0, 0, 0, 0.3) 2.95px 2.95px 6.6px;
}

.button-save-style:hover {
  background-color: #204268;
  color: white;
}

.button-save-style:focus {
  box-shadow: none !important;
}

.button-delete-row {
  background-color: white;
  border: none;
  padding: 0;
  margin: 0;
}